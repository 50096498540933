import React, {useContext, useState} from "react";
import "./Modal.css";
import { ProgressContext } from '../../../App'

function ArtifactList({ setOpenModal, setBookArtifactOpen, setAudioArtifactOpen, setIDArtifactOpen }) {
  const progressContext = useContext(ProgressContext);
  const [showIdCardSelect, setShowIdCardSelect] = useState(false);

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Artifacts</h1>
        </div>
          <ul className="artifactTable" style={{marginTop: "8vh", columns: 1}} >
            <ul className="artifactHeader">
              <li><b>File</b></li>
              <li><b>Type</b></li>
              <li><b>Owner</b></li>
              <li><b>Status</b></li>
            </ul>
            <ul >
              <li> <button onClick={()=>setIDArtifactOpen(true)}>document_006.png</button></li>
              <li><a>Student ID</a></li>
              <li><a>Select</a> <button>></button></li>
              <li><a> Unapproved </a></li>
            </ul>
            <ul >
              <li> <button onClick={()=>setBookArtifactOpen(true)}>document_021.png</button></li>
              <li><a>Student ID</a></li>
              <li><a>Select</a> <button>></button></li>
              <li><a> Unapproved </a></li>
            </ul>
            <ul >
              <li> <button onClick={()=>setAudioArtifactOpen(true)}>document_009.mp3</button></li>
              <li><a>Cellphone - Voicemail</a></li>
              <li><a>Select</a> <button>></button></li>
              <li><a> Unapproved </a></li>
            </ul>
          </ul> 
          {showIdCardSelect && <div className='characterActionMenu' style={{top: `${30 + 2}vw`, left: `${50 + 8}vw`}}>
            <button>Talk</button>
            <button>Practice Introductions</button>
          </div>}  
      </div>
    </div>
  );
}

export default ArtifactList;