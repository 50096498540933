import React from "react";
import "./RadioButtons.css";

function RadioButtons({radioList, currentRadioValue, setRadioValue}) {
  return (
    <div className="buttondDiv">
      
      {radioList && radioList.map((bulletInfo, index) => (
            <label class="radio-button">
            <input defaultChecked={currentRadioValue===bulletInfo} type="radio"
              onChange={()=>{
                if(currentRadioValue!==bulletInfo) {
                  setRadioValue(bulletInfo)
                }
                }} name="radio" />
            <span class="label-visible">
                <span class="fake-radiobutton"></span>
                {bulletInfo}
            </span>
            </label>
      ))}
    </div>
  );
}

export default RadioButtons;