import React, {useState} from "react";
import HoverTranslationSuggestion from "../HoverTranslation/HoverTranslationSuggestion";
import "./styles.css";

const UserInputSuggestions = ({ conversationTopic, responseConvTopic, possibleTopicList, 
    suggestions, suggestionTranslations, suggestionsFurigana,
    fuqSuggestions, fuqSuggestionsTranslations, fuqFurigana,
    promptSuggestions, promptSuggestionsTranslations, promptFurigana,
    isAudioDisable, disableAudioFunction, suggDisplay, whichSuggestionsSetting,
    isFuriganaEnabled, isSemanticTranslationEnabled}) => {


  return (
            <div style={{margin: "10px", overflowY: "scroll", height: "15vw"}}>
                {/* <p> convTopic: {conversationTopic} responseTopic: {responseConvTopic} </p> */}
                {whichSuggestionsSetting === "Basic" ?
                    <div style={suggDisplay}>
                        {suggestions && suggestions.map((data, index) => (
                            <HoverTranslationSuggestion key={data[0]} phrase={data[0]}
                            phraseTranslation={suggestionTranslations[index][0]} furigana={suggestionsFurigana[index][0]}
                            convTopic={conversationTopic} isFuqPhrase={false} isAudioDisabled={isAudioDisable}
                            disableAudioFunction={disableAudioFunction} 
                            isFuriganaEnabled={isFuriganaEnabled} isSemanticTranslationEnabled={isSemanticTranslationEnabled}/> 
                       
                        ))}

                        {fuqSuggestions && fuqSuggestions.map((data, index) => (
                            <HoverTranslationSuggestion key={data[0]} phrase={data[0]}
                            phraseTranslation={fuqSuggestionsTranslations[index][0]} furigana={fuqFurigana[index][0]}
                            convTopic={conversationTopic} isFuqPhrase={true} isAudioDisabled={isAudioDisable}
                            disableAudioFunction={disableAudioFunction}
                            isFuriganaEnabled={isFuriganaEnabled} isSemanticTranslationEnabled={isSemanticTranslationEnabled}/> 
                       
                        ))}

                    </div> :
                    <div style={suggDisplay}>
                    {fuqSuggestions && fuqSuggestions.map((data, index) => (
                        data && data.map((innerData, innerIndex) => (
                            <HoverTranslationSuggestion key={innerData} phrase={innerData}
                            phraseTranslation={fuqSuggestionsTranslations[index][innerIndex]} furigana={fuqFurigana[index][innerIndex]}
                            convTopic={conversationTopic} isFuqPhrase={true} isAudioDisabled={isAudioDisable}
                            disableAudioFunction={disableAudioFunction}
                            isFuriganaEnabled={isFuriganaEnabled} isSemanticTranslationEnabled={isSemanticTranslationEnabled}/> 
                        ))
                    ))}
                    {suggestions && suggestions.map((data, index) => (
                        data && data&& data.map((innerData, innerIndex) => (
                            <HoverTranslationSuggestion key={innerData} phrase={innerData}
                            phraseTranslation={suggestionTranslations[index][innerIndex]} furigana={suggestionsFurigana[index][innerIndex]}
                            convTopic={conversationTopic} isFuqPhrase={false} isAudioDisabled={isAudioDisable}
                            disableAudioFunction={disableAudioFunction}
                            isFuriganaEnabled={isFuriganaEnabled} isSemanticTranslationEnabled={isSemanticTranslationEnabled}/> 
                        ))
                    ))}
                    {promptSuggestions && promptSuggestions.map((data, index) => (
                        data && data.map((innerData, innerIndex) => (
                            <HoverTranslationSuggestion key={innerData} phrase={innerData}
                            phraseTranslation={promptSuggestionsTranslations[index][innerIndex]} furigana={promptFurigana[index][innerIndex]}
                            convTopic={conversationTopic} isFuqPhrase={false} isAudioDisabled={isAudioDisable}
                            disableAudioFunction={disableAudioFunction}
                            isFuriganaEnabled={isFuriganaEnabled} isSemanticTranslationEnabled={isSemanticTranslationEnabled}/> 
                        ))
                    ))}
                </div> 
                }
            </div>
  );
};

export default UserInputSuggestions;
