import "./styles/App.css";
import { useState, createContext, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Recoder from "./Components/ConversationComponents/Recorder";
import OpenConv from "./Components/ConversationComponents/OpenConv";
import OpenConvRen from "./Components/ConversationComponents/OpenConvRen";
import MainNavPage from "./Components/HomeComponents/MainNavPage";
import NavMap from "./Components/HomeComponents/NavMap";
import StartMenu from "./Pages/StartMenu/StartMenu";
import Cutscene from "./Pages/Cutscene/Cutscene";
import welcomeBoukyakuStory from "./CutsceneData/story/cutsceneDialogues/welcomeBoukyakuStory";
import introductionsLessonStory from "./CutsceneData/story/cutsceneDialogues/introductionsLessonStory";
import demoConclusionStory from "./CutsceneData/story/cutsceneDialogues/demoConclusionStory";

export const ProgressContext = createContext();

export default function App() {
  const [progress, setProgress] = useState({
    contentExplanation: false,      // unlocks introduction lesson
    introductionLesson: false,      // unlock grammar, vocab and introduction guided convo
    introductionGuidedConv: false,  // unlocks open conversations
    openConversation: false,        // unlocks artifact and rest of guided conv
    conclusionCutscene: false,       // prevents cutscene from occuring again
    user_name: "",

    madeMistake: false
  });

  return (
    <ProgressContext.Provider value={{progress: progress, setProgress: setProgress}}>
      <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<StartMenu />} />
          <Route path="chapterNav" element={<NavMap />} />
          <Route path="OGUI" element={<MainNavPage />} />
          <Route path="guidedConvDemoIntro" element={<div className="App"><Recoder convType="" /></div>} />
          <Route path="guidedConvDemoSmallTalk" element={<div className="App"><Recoder convType="repeatableQuestions" /></div>} />
          <Route path="guidedConvDemoAboutYou" element={<div className="App"><Recoder convType="moreInfo1" /></div>} />
          <Route path="openConvDemoYuto" element={<div className="App"><OpenConv /></div>} />
          <Route path="openConvDemoRen" element={<div className="App"><OpenConvRen /></div>} />
          <Route path="welcome" element={<Cutscene title={"Welcome to Boukyaku"} story={welcomeBoukyakuStory} completionProgressVar={"contentExplanation"} progress={progress} setProgress={setProgress} />} />
          <Route path="introductionsLesson" element={<Cutscene title={"Introductions"} story={introductionsLessonStory} completionProgressVar={"introductionLesson"} progress={progress} setProgress={setProgress} />} />
          <Route path="conclusionScene" element={<Cutscene title={"Conclusion"} story={demoConclusionStory} completionProgressVar={"conclusionCutscene"} progress={progress} setProgress={setProgress} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ProgressContext.Provider>
  );
}
