import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './NavMap.css';
import NoteModal from "../ConversationComponents/Modal/NoteModal";
import VocabModal from "../ConversationComponents/Modal/VocabModal";
import TasksModal from "../ConversationComponents/Modal/TasksModal";
import ConversationModal from "../ConversationComponents/Modal/ConversationModal";
import ConversationModalSmallTalk from "../ConversationComponents/Modal/ConversationModalSmallTalk";
import ConversationModalAboutYou from "../ConversationComponents/Modal/ConversationModalAboutYou";
import OpenConversationModal from "../ConversationComponents/Modal/OpenConversationModal";
import ArtifactModal from "../ConversationComponents/Modal/ArtifactModal";
import ArtifactModalAudioMessage from "../ConversationComponents/Modal/ArtifactModalAudioMessage";
import ArtifactStudentID from '../ConversationComponents/Modal/ArtifactModalStudentID';
import ArtifactList from '../ConversationComponents/Modal/ArtifactListModal';
import ArtifactSelectionModal from '../ConversationComponents/Modal/ArtifactSelectionModal';
import ExConvsModal from "../ConversationComponents/Modal/ExConvsModal";
import DemoIntro from "../ConversationComponents/Modal/DemoIntro";
import Sound from "react-sound";
import menuMusic from '../../CutsceneData/story/bgm/menuMusic.mp3'; 
import { ProgressContext } from '../../App';
import Map from '../../styles/mapPH.jpg'
import KyokoIcon from '../../styles/Kyoko/KyokoIcon.png'
import YutoIcon from '../../styles/Yuto/YutoIcon.png'
import DaichiIcon from '../../styles/Daichi/DaichiIcon.png'
import RenIcon from '../../styles/Ren/RenIcon.png'

import diaryIcon from "./diaryIcon.png"
import diaryIconAlt from "./diaryIconAlt.png"
import diaryIconLocked from "./diaryIconLocked.png"
import phoneIcon from "./phoneIcon.png"
import phoneIconAlt from "./phoneIconAlt.png"
import phoneIconLocked from "./phoneIconLocked.png"
import stickyNoteIcon from "./stickyNoteIcon.png"
import lockIconDark from "./LockIconDark.png"
import lockIconWhite from "./LockIconWhite.png"
import newStar from "./yellowStar.png"
import stickyNoteIconGreen from "./stickyNoteIconGreen.png"

function NavMap() {
  const progressContext = useContext(ProgressContext);
  const [DemoInfoModalOpen, setDemoInfoModalOpen] = useState(false);
  const [TasksModalOpen, setTasksModalOpen] = useState(false);
  const [NotesModalOpen, setNotesModalOpen] = useState(false);
  const [VocabModalOpen, setVocabModalOpen] = useState(false);
  const [ConversationModalOpen, setConversationModalOpen] = useState(false);
  const [ConversationModalSmallTalkOpen, setConversationModalSmallTalkOpen] = useState(false);
  const [ConversationModalAboutYouOpen, setConversationModalAboutYouOpen] = useState(false);
  const [OpenConversationModalOpen, setOpenConversationModalOpen] = useState(false);
  const [ArtifactModalOpen, setArtifactModalOpen] = useState(false);
  const [BookArtifactModalOpen, setBookArtifactModalOpen] = useState(false);
  const [AudioArtifactModalOpen, setAudioArtifactModalOpen] = useState(false);
  const [StudentIDArtifactModalOpen, setStudentIDArtifactModalOpen] = useState(false);
  const [ExConvsModalOpen, setExConvsModalOpen] = useState(false);
  const [showKyokoActionMenu, setShowKyokoActionMenu] = useState(false);
  const [showRenActionMenu, setShowRenActionMenu] = useState(false);
  const [showDaichiActionMenu, setShowDaichiActionMenu] = useState(false);
  const [showYutoActionMenu, setShowYutoActionMenu] = useState(false);
  const sidebarItems = ['Chapter 0'];
  const artifact1Unlocked = false;
    

    return (
        <div>
          <div className='navScreenDiv'>
            <img src={Map} className='navmap'></img>
          </div>

          <img className='characterIcon' src={DaichiIcon} style={{top: '24.3vw', left: '56vw'}}
          onClick={()=> setShowDaichiActionMenu(!showDaichiActionMenu)}></img>

          {progressContext.progress.introductionLesson && <img className='characterIcon' src={KyokoIcon} style={{top: '25.5vw', left: '26vw'}}
          onClick={()=> setShowKyokoActionMenu(!showKyokoActionMenu)}></img>}

          {progressContext.progress.introductionGuidedConv && <img className='characterIcon' src={YutoIcon} style={{top: '27.5vw', left: '85vw'}}
          onClick={()=> setShowYutoActionMenu(!showYutoActionMenu)}></img>}

          {progressContext.progress.introductionGuidedConv && <img className='characterIcon' src={RenIcon} style={{top: '19.3vw', left: '47vw'}}
          onClick={()=> setShowRenActionMenu(!showRenActionMenu)}></img>}

          {showKyokoActionMenu && <div className='characterActionMenu' style={{top: `${25.5 + 2}vw`, left: `${26 + 6.5}vw`}}>
          <Link to="/guidedConvDemoIntro" style={{textDecoration: "none"}}><button>Practice Introductions</button></Link>
          </div>}

          {showDaichiActionMenu && <div className='characterActionMenu' style={{top: `${24.3 + 2}vw`, left: `${56 + 6.5}vw`}}>
          <Link to="/introductionsLesson" style={{textDecoration: "none"}}><button>Introductions Lesson</button> </Link>
            {/* <button>Review Artifact Assignments</button> */}
          </div>}

          {showYutoActionMenu && <div className='characterActionMenu' style={{top: `${27.5 + 2}vw`, left: `${85 - 9.4}vw`}}>
          <Link to="/openConvDemoYuto" style={{textDecoration: "none"}}><button style={{paddingLeft: "4vw", paddingRight: "4vw"}}>Talk</button></Link>
            {/* <button>Practice Introductions</button> */}
          </div>}

          {showRenActionMenu && <div className='characterActionMenu' style={{top: `${19.3 + 2}vw`, left: `${47 + 6.5}vw`}}>
          <Link to="/openConvDemoRen" style={{textDecoration: "none"}}><button>Talk</button></Link>
            {/* <button>Practice Introductions</button> */}
          </div>}

          <div className='itemsNavigation'>
          <button onClick={() => {setTasksModalOpen(true); }}>
          Tasks {!progressContext.progress.introductionLesson && <img src={newStar}/>}</button>
          {/* <button onClick={() => {setArtifactModalOpen(true)}}>Artifacts</button> */}
          <button onClick={() => {setVocabModalOpen(true)}} disabled={!progressContext.progress.introductionLesson} className={!progressContext.progress.introductionLesson ? 'disabled' : ''}>
          Vocabulary {!progressContext.progress.introductionLesson && <img src={lockIconWhite}/>}
                    {progressContext.progress.introductionLesson && !progressContext.progress.introductionGuidedConv && <img src={newStar}/>}</button>
          <button onClick={() => {setNotesModalOpen(true); }} disabled={!progressContext.progress.introductionLesson} className={!progressContext.progress.introductionLesson ? 'disabled' : ''}>
                    Grammar Notes {!progressContext.progress.introductionLesson && <img src={lockIconWhite}/>}
                    {progressContext.progress.introductionLesson && !progressContext.progress.introductionGuidedConv && <img src={newStar}/>}</button>
          <button onClick={() => {setDemoInfoModalOpen(true); }}>Help</button>
          </div>
          


          {DemoInfoModalOpen && <DemoIntro setOpenModal={setDemoInfoModalOpen} />}
          {TasksModalOpen && <TasksModal setOpenModal={setTasksModalOpen} />}
          {NotesModalOpen && <NoteModal setOpenModal={setNotesModalOpen} />}
          {VocabModalOpen && <VocabModal setOpenModal={setVocabModalOpen} />}
          {ConversationModalOpen && <ConversationModal setOpenModal={setConversationModalOpen} />}
          {ConversationModalSmallTalkOpen && <ConversationModalSmallTalk setOpenModal={setConversationModalSmallTalkOpen} />}
          {ConversationModalAboutYouOpen && <ConversationModalAboutYou setOpenModal={setConversationModalAboutYouOpen} />}
          {OpenConversationModalOpen && <OpenConversationModal setOpenModal={setOpenConversationModalOpen} />}
          {ArtifactModalOpen && <ArtifactList setOpenModal={setArtifactModalOpen} setBookArtifactOpen={setBookArtifactModalOpen} setAudioArtifactOpen={setAudioArtifactModalOpen} setIDArtifactOpen={setStudentIDArtifactModalOpen} />}
          {BookArtifactModalOpen && <ArtifactModal setOpenModal={setBookArtifactModalOpen} />}
          {AudioArtifactModalOpen && <ArtifactModalAudioMessage setOpenModal={setAudioArtifactModalOpen} />}
          {StudentIDArtifactModalOpen && <ArtifactStudentID setOpenModal={setStudentIDArtifactModalOpen} />}
          {ExConvsModalOpen && <ExConvsModal setOpenModal={setExConvsModalOpen} />}
        </div>
      );
    }

export default NavMap;
