import React from "react";
import "./Modal.css";
import kyokoFace from "./charFaceShots/KyokoFaceShot.png"
import yutoFace from "./charFaceShots/YutoFaceShot.png"
import MissingBag_Phone from "./MissingBag_Phone.png"
import Voicemail from "./voicemail.png"
import vtest from "./test1.mp3"
import backIcon from "../../HomeComponents/backIcon.png"

import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";


function ConversationModal({ setOpenModal, translationSettings, suggestionsSettings }) {
  const vocab = [  {    "word": "こんにちは",    "translation": "Hello"  },  {    "word": "はい",    "translation": "Yes"  },  {    "word": "いいえ",    "translation": "No"  },  {    "word": "さようなら",    "translation": "Goodbye"  },  {    "word": "おはようございます",    "translation": "Good morning"  },  {    "word": "こんばんは",    "translation": "Good evening"  },  {    "word": "ありがとう",    "translation": "Thank you"  },  {    "word": "すみません",    "translation": "Excuse me/sorry"  },  {    "word": "はじめまして",    "translation": "Nice to meet you"  },  {    "word": "わたし",    "translation": "I/me"  },  {    "word": "あなた",    "translation": "You"  },  {    "word": "せんせい",    "translation": "Teacher"  },  {    "word": "がくせい",    "translation": "Student"  },  {    "word": "いぬ",    "translation": "Dog"  },  {    "word": "ねこ",    "translation": "Cat"  },  {    "word": "ほん",    "translation": "Book"  },  {    "word": "ざっし",    "translation": "Magazine"  },  {    "word": "しんぶん",    "translation": "Newspaper"  },  {    "word": "てちょう",    "translation": "Notebook"  },  {    "word": "めいし",    "translation": "Business card"  }]

  return (
    <div className="modalBackground artifactModalBackground">
      <div className="modalContainer artifactModalContainer">
        <div style={{justifyContent: 'flex-start'}} className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img style={{height: '4vh'}} src={backIcon}></img>
          </button>
        </div>
        
        <img className="phoneImage"  src={Voicemail}/>
        <div className="phoneAudioMessageDiv" style={{ width: "auto"}}>
        <AudioPlayer style={{ borderRadius: "1rem", color: "rgb(255 255 255);", background: "rgb(255 255 255);", width: "auto!important", height: "auto!important"}}
          src={vtest}  showSkipControls={false}
          showJumpControls={false}/>
        </div>
          
      </div>
    </div>
  );
}

export default ConversationModal;