import React, { useEffect, useState, useContext } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import HoverTranslation from "./HoverTranslation/HoverTranslation";
import UserInputSuggestions from "./Suggestions/UserInputSuggestions";
import "./recorder.css";
import ConvoSidebar from "./ConvoSidebar/index.js";
import { ProgressContext } from "../../App";

//Character Portraits
import neutral_listen from '../../styles/Yuto/neutralListening.png'
import neutral_talk from '../../styles/Yuto/neutralSpeaking.png'
import happy_listen from '../../styles/Kyoko/happyListening.png'
import happy_talk from '../../styles/Kyoko/happySpeaking.png'
import sad_listen from '../../styles/Kyoko/sadListening.png'
import sad_talk from '../../styles/Kyoko/sadSpeaking.png'
import confused_listen from '../../styles/Kyoko/confusedListening.png'
// BG Images
//import backgroundImage from '../../styles/backgroundImages/OpenConv/uiBackground.png'
import backgroundImage from '../../styles/backgroundImages/forest.jpg'
import tableCover from '../../styles/backgroundImages/OpenConv/uiCover.png'

import ksbutton from '../../styles/KSbutton.png'

const Kyokos = [
  neutral_listen,
  neutral_talk,
  happy_listen,
  happy_talk,
  sad_listen,
  sad_talk,
  confused_listen
]

const characterName = "優翔"

const Recorder = () => {
  const progressContext = useContext(ProgressContext);

  const [recordState, setrecordstate] = useState(RecordState.STOP);
  const [isRecording, setIsRecording] = useState(false);
  const [suggestionsDisplay, setSuggestionsDisplay] = useState({display: 'none'});
  const [userName, setUserName] = useState("さくら");
  const [loading, setLoading] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);

  const [oldMessages, setOldMessages] = useState(["old", "messages", "go", "here"]);
  const listItems = oldMessages.map((number) =>
    <li>{number}</li>
  );

  //variables for changing chat text and emotion
  const [currentResponse, setCurrentResponse] = useState("");
  const [currentRespTransObj, setCurrentRespTransObj] = useState({});
  const [currentResponseAudio, setCurrentResponseAudio] = useState("");
  const [currentCharacterPrompt, setCurrentCharacterPrompt] = useState("");
  const [currentPromptTransObj, setCurrentPromptTransObj] = useState({});
  const [currentPromptAudio, setCurrentPromptAudio] = useState("");
  const [currentCharacterPortrait, setCurrentCharacterPortrait] = useState(0);
  const [userInputText, setUserInputText] = useState(" ");

  //const systemMessage = { role: "system", content: "Act as a 24 year old woman from japan named 京子 and only use N5 grammar and vocabulary to respond in the context of the entire converation using  1-2 sentences. Include an english translation of what you're saying in parantheses." }
  const systemMessage = { role: "system", content: "Act as a 24 year old man from japan named 優翔 who's favorite video game is 'Legend of Zelda: Majora's Mask' and thinks the Harvest Moon games are boring. Only use N5 grammar and vocabulary to respond in the context of the entire converation. Respond using 1 sentence. Include an english translation of what YOU as the character are saying in parantheses. YOU MUST ONLY RESPOND IN JAPANESE USING HIRAGANA, KATAKANA AND KANJI, DO NOT RESPOND IN ANY OTHER LANGUAGE. You only know Japanese and do not understand any other languages." }
  // const systemMessage= {role: "system", content: "Act as a 17 year old girl from japan named 蓮 who likes edgy internet content and starting arguments with people and lives in Tokyo and finds school a little boring. Only use N5 grammar and vocabulary to respond in the context of the entire converation. Talk using short simple sentences. Try to only respond using 1 sentence. Include an english translation of what YOU as the character are saying in parantheses. YOU MUST ONLY RESPOND IN JAPANESE USING HIRAGANA, KATAKANA AND KANJI, DO NOT RESPOND IN ANY OTHER LANGUAGE. You only know Japanese and do not understand any other languages."}
  //const systemMessage = { role: "system", content: "Act as a 25 year old man from japan named 優翔 and only use n5 grammar and vocabulary to respond in 1-2 sentences." }
  //const messages = [{ role: "system", content: "Pretend you are a 25 year old man from japan named 優翔 who's favorite game is 'Legend of Zelda: Majora's Mask. Only use n5 grammar and vocabulary to respond in 1-2 sentences." }];

  const [convMessages, setConvMessages] = useState([systemMessage]);
  
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);

  //variables for storing conversation state (meant to be passed pack and forth to chatbot back end)
  const [convTopic, setConvTopic] = useState("greeting");
  const [charOverallTopic, setCharOverallTopic] = useState("none");
  const [possibleTopics, setPossibleTopics] = useState(["whatIsYourName", "introduction"]);
  const [mentionedTopics, setMentionedTopics] = useState([]);
  const [answeredTopics, setAnsweredTopics] = useState([]);
  const [characterEmotionPoints, setCharacterEmotionPoints] = useState(35);

  // CORRECTION Vars
  const [correctionInProcess, setCorrectionInProcess] = useState(false);
  const [affirmedCorrectionChatbotData, setAffirmedCorrectionChatbotData] = useState({});
  const [deniedCorrectionChatbotData, setDeniedCorrectionChatbotData] = useState({});
  // TRANSALATION Vars
  const [translationTypes, setTranslationTypes] = useState(["Semantic Translation", "Word by Word", "Furigana"]);
  const [showSemanticTranslation, setShowSemanticTranslation] = useState(true);
  const [wordByWordTrans, setWordByWordTrans] = useState([]);
  const [showWordTranslation, setShowWordTranslation] = useState(true);
  const [showFurigana, setShowFurigana] = useState(true);
  const [responseFurigana, setResponseFurigana] = useState("")
  const [promptFurigana, setPromptFurigana] = useState("")
  // SUGGESTION Vars
  const [suggestionsSetting, setSuggestionsSetting] = useState("Basic");
  const [suggestionsSettingTypes, setSuggestionsSettingTypes] = useState(["Basic", "All Possible", "One of Each"]);
  const [userInputSuggestionsArrays, setUserInputSuggestionsArrays] = useState([]);
  const [userInputSuggestionTranslationArrays, setUserInputSuggestionranslationArrays] = useState([]);
  const [userInputSuggestionFuriganaArrays, setUserInputSuggestionFuriganaArrays] = useState([]);
  const [promptSuggestionsArrays, setPromptSuggestionsArrays] = useState([]);
  const [promptSuggestionTranslationArrays, setPromptSuggestionTranslationArrays] = useState([]);
  const [promptSuggestionFuriganaArrays, setPromptSuggestionFuriganaArrays] = useState([]);
  const [followUpQSuggestionsArrays, setFollowUpQSuggestionsArrays] = useState([]);
  const [fuqSuggestionsTranslationsArrays, setFuqSuggestionsTranslationsArrays] = useState([]);
  const [fuqSuggestionFuriganaArrays, setFuqSuggestionFuriganaArrays] = useState([]);
  // SUGGESTION and TRANSLATION Setting Funcs
  const changeSuggestionSetting = (newSetting) => {
    setSuggestionsSetting(newSetting);
    // fetchAndSetUserInputSuggestions(convTopic, charOverallTopic, newSetting)
  }
  const setSuggestionArrays = (phrases, translations, furi, setSuggs, setSuggTrans, setSuggFuri) => {
    setSuggs(phrases)
    setSuggTrans(translations)
    setSuggFuri(furi)
  }

  const [DemoInfoModalOpen, setDemoInfoModalOpen] = useState(true);

  //EMOTION SETTING AND INTERPRETTING FUNCTIONS
  const getEmotionalState = (emotionPoints) => {
    if(emotionPoints < -100) {
      return "angry"
    }
    else if (emotionPoints < 0) {
      return "sad"
    }
    else if (emotionPoints < 50) {
      return "neutral"
    }
    else {
      return "happy"
    }
  }

  //used for getting the emotion for portrait
  const getEmotionalStateForPhrase = (newEmotionPointsTotal, temporaryPhraseEmotion) => {
    if (temporaryPhraseEmotion !== "none") {
      return temporaryPhraseEmotion
    }
    return getEmotionalState(newEmotionPointsTotal)
  }


  // Fetch and set functions for api calls

  const fetchFurigana = async (phrase, setterFunc) => {
      fetch('https://kyokos-friends-node-backend-zelhqvxj6q-uc.a.run.app/furigana', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          aiDialog: phrase
        })
      }).then((response) => response.json())
        .then((data) => {
          setterFunc(data.message)
        });
  }
  
  const getAndSetWordByWordTrans = async (phraseToTranlate) => {
    const noPunctuationPhrase = phraseToTranlate.replace(/[^\p{L}\p{N}\s]|_/gu, "").replace(/\s+/g, " ");
    console.log("WORD BY WORD TRANS: ", phraseToTranlate)
    console.log(noPunctuationPhrase)
    fetch('https://kyokos-friends-node-backend-zelhqvxj6q-uc.a.run.app/chatGTPTbtTranslation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phrase: noPunctuationPhrase
      })
    }).then((response) => response.json())
      .then((data) => {
        console.log("Token by Token Translation")
        console.log(data)
        setWordByWordTrans(data.tbtTranslation)
    });
}


  // const getAndPlayVoiceFile = (fileChoiceParamObj, playPromptAfter, emotion, voiceClipSelectObj) => {
  //   fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getAiGeneratedVoice', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(fileChoiceParamObj)
  //   }).then((response) => response.blob()).then((data) => playCharacterSpeech(data, playPromptAfter, emotion, voiceClipSelectObj)).catch((error) => {
  //     console.log("axios error:", error);
  //   });
  // }
  const getAndPlayVoiceFile = (fileChoiceParamObj, playPromptAfter, emotion, voiceClipSelectObj) => {
  // fetch('https://voicevoxengine-zelhqvxj6q-uc.a.run.app/audio_query?text='+ fileChoiceParamObj.dialog +'&speaker=6', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   }).then((response) => response.json())
  //     .then((VVSynthesisData_fetched) => {
  //       console.log("VOICEVOX FIRST CALL", VVSynthesisData_fetched);
  //       fetch('https://voicevoxengine-zelhqvxj6q-uc.a.run.app/synthesis?speaker=6&enable_interrogative_upspeak=true', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(VVSynthesisData_fetched)
  //       }).then((response) => response.blob()).then((data) => playCharacterSpeech(data, playPromptAfter, emotion, voiceClipSelectObj)).catch((error) => {
  //         console.log("axios error:", error);
  //       });
  //     });
      setLoading(true)
      fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceVoxVoiceBlob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phrase: fileChoiceParamObj.dialog,
          speaker: '41'
        })
      }).then((response) => response.blob()).then((data) => {
        setLoading(false)
        playCharacterSpeech(data, playPromptAfter, emotion, voiceClipSelectObj)
      })
      .catch((error) => {
            setLoading(false)
            console.log("axios error:", error);
          });
    // fetch('http://localhost:50021/synthesis?speaker=6&enable_interrogative_upspeak=true', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(vvSynthesisData)
    // }).then((response) => response.blob()).then((data) => playCharacterSpeech(data, playPromptAfter, emotion, voiceClipSelectObj)).catch((error) => {
    //   console.log("axios error:", error);
    // });
  }

  const getAndSetTranslationObj = (phrase, translationLocation) => {
    console.log("making independent Translation fetch")
    // response
    if (translationLocation === 0) {
      fetchFurigana(phrase, setResponseFurigana)
    }
    // prompt
    if (translationLocation === 1) {
      fetchFurigana(phrase, setPromptFurigana)
    }

    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getTextTranslation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "aiDialog": phrase,
        "calleeName": characterName,
        "userName": userName
      })
    }).then((response) => response.json())
      .then((data) => {
        if (translationLocation === 0) {
          setCurrentRespTransObj(data)
        }
        else if (translationLocation === 1) {
          setCurrentPromptTransObj(data)
        }
        else if (translationLocation === 2) {
          console.error("THIS DIALOGUE LOCATION DOES NOT EXIST YET")
        }
      });
  }





  //      Functions for processing user input and setting conversation meta variables


  const startConversation = (convTopicsListName) => {
    // if (convTopicsListName === "moreInfo1"){
    //   setPossibleTopics(["howOldAreYou", "whatCountryAreYouFrom", "whatLanguageYouSpeak_日本語", "whatLanguageYouSpeak_英語"])
    // }
    // else if (convTopicsListName === "repeatableQuestions"){
    //   setPossibleTopics([ "howAreYou", "howIsTheWeather", "howIsTheTemperature"])
    // }
    // //need to generate greeting based on mood and time of day here
    // var greetingParams = { "convTopic": "greeting", "characterName": characterName, "userName": userName, "currentCharacterEmotion": getEmotionalState(characterEmotionPoints)}
    // fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getGreetingData', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(greetingParams)
    // }).then((response) => response.json())
    //   .then((data) => {
    //     console.log(data)
    //     setCharacterDialogueOutputs("blank", "", "none", null,
    //       "", data["characterEmotion"], "greeting", data["promptTransObj"],
    //       0)

    //       const starterChatbotData = {
    //         "aiPrompt": "",
    //         "aiResponse": "blank",
    //         "answeredTopics": answeredTopics,
    //         "correction": "",
    //         "currentConvTopic": convTopic,
    //         "mentionedTopics": mentionedTopics,
    //         "possibleTopics": possibleTopics,
    //         "promptEmotion": "neutral",
    //         "promptTransObj": "",
    //         "responseConvTopic": "none",
    //         "responseEmotion": "neutral",
    //         "responseTransObj": console.log("null"),
    //         "emotionPointsChange": 0
    //         }
    //       setDeniedCorrectionChatbotData(starterChatbotData)

    //   }).catch((error) => {
    //     console.log("axios error:", error);
    //   });

    setIsConversationStarted(true)
  }


  //may have to  call "play character speech" here and handle response and prompt output -> send to old Messages
  const processAiDialogue = (chatbotData) => {
    console.log("Setting Ai Dialogue and chatbotData js vars with following:")
    console.log(chatbotData)

    //setting conversation tracking variables
    setConvTopic(chatbotData["currentConvTopic"]);
    
    setPossibleTopics(chatbotData["possibleTopics"]);
    setMentionedTopics(chatbotData["mentionedTopics"]);
    setAnsweredTopics(chatbotData["answeredTopics"]);

    //setCharacterEmotionPoints(characterEmotionPoints + chatbotData["emotionPointsChange"]);

    setCharacterDialogueOutputs(chatbotData["aiResponse"], chatbotData["responseEmotion"], chatbotData["responseConvTopic"], chatbotData["responseTransObj"],
      chatbotData["aiPrompt"], chatbotData["promptEmotion"], chatbotData["currentConvTopic"], chatbotData["promptTransObj"],
      chatbotData["emotionPointsChange"])


  };


  //called for changing character dialogue 
  const setCharacterDialogueOutputs = (response, responseEmotion, responseConvTopic, responseTransObject, 
    prompt, promptEmotion, immediateConvTopic, promptTransObject, emotionPoints) => {
    console.log("EMOTION POINTS CURRENTLY: " + characterEmotionPoints)
    setCurrentResponse(response);
    setCurrentCharacterPrompt(prompt);

    setCurrentPromptAudio(null)
    setCurrentResponseAudio(null)


    if (response !== "blank" && responseTransObject !== null) {
      fetchFurigana(response, setResponseFurigana)
      setCurrentRespTransObj(responseTransObject) 
    }
    else { getAndSetTranslationObj(response, 0) }
    if (prompt !== "blank" && promptTransObject !== null) {
      fetchFurigana(prompt, setPromptFurigana)
      setCurrentPromptTransObj(promptTransObject) 
    }
    else { getAndSetTranslationObj(prompt, 1) }

    //Set new emotion points value here, then pass total into fetchAndPlayResponse
    var newCharacterEmotionPoints = emotionPoints + characterEmotionPoints
    setCharacterEmotionPoints(newCharacterEmotionPoints)

    const charVoiceSelectionData = {
      "response": response, "responseTopic": responseConvTopic, "responseEmotion": responseEmotion,
      "prompt": prompt, "promptTopic": immediateConvTopic, "promptEmotion": promptEmotion,
      "emotionPoints": newCharacterEmotionPoints
    }

    fetchAndPlayResponse(charVoiceSelectionData)

  }

  const fetchAndPlayResponse = (voiceClipSelectObj) => {
    if(voiceClipSelectObj["response"] !== "blank" ||  voiceClipSelectObj["prompt"] !== "blank")
    {
      setDisableInteraction(true)
    }
    if (voiceClipSelectObj["response"] !== "blank") {
      console.log("Fetching response audio")

      var playPromptAfter = true
      if (voiceClipSelectObj["prompt"] === "blank") {
        playPromptAfter = false
      }

      //For Response: Get the appropriate voice file here and call function that will set text and char image appropriately
          //In the future this is where "emotionImpact" value from phrases will be factored in
      const responseEmotion = getEmotionalStateForPhrase(voiceClipSelectObj["emotionPoints"], voiceClipSelectObj["responseEmotion"])
      //"emotion" for mp3ChoiceParams is the emotion of the voice file
      var mp3ChoiceParams = { "promptOrResponse": "response", "dialog": voiceClipSelectObj["response"], "convtopic": voiceClipSelectObj["responseTopic"], "emotion": responseEmotion, "character": characterName }
      //"emotion" parameter for getAndPlayVoiceFile funtion is the emotion of the character portrait
      getAndPlayVoiceFile(mp3ChoiceParams, playPromptAfter, responseEmotion, voiceClipSelectObj)
    }
    else {
      fetchAndPlayPrompt(voiceClipSelectObj)
    }
  }

  const fetchAndPlayPrompt = (voiceClipSelectObj) => {
    if (voiceClipSelectObj["prompt"] !== "blank") {
      console.log("Fetching prompt audio")
      //For Response: Get the appropriate voice file here and call function that will set text and char image appropriately
          //In the future this is where "emotionImpact" value from phrases will be factored in
      const promptEmotion = getEmotionalStateForPhrase(voiceClipSelectObj["emotionPoints"], voiceClipSelectObj["promptEmotion"])
      //"emotion" for mp3ChoiceParams is the emotion of the voice file
      var mp3ChoiceParams = { "promptOrResponse": "prompt", "dialog": voiceClipSelectObj["prompt"], "convtopic": voiceClipSelectObj["promptTopic"], "emotion": promptEmotion, "character": characterName }
      //"emotion" parameter for getAndPlayVoiceFile funtion is the emotion of the character portrait
      getAndPlayVoiceFile(mp3ChoiceParams, false, promptEmotion, voiceClipSelectObj)
    }
  }




  //    Functions for playing character speech (and setting portraits to match)



  //plays character dialogue and sets portrait to talking for length of audio clip
  const playCharacterSpeech = (audioBlob, playPromptAfter, emotion, voiceClipSelectObj) => {
    console.log(audioBlob.size)

    //don't play audio and set speaking if audio data is empty
    if (audioBlob.size > 100) {
      var audioURL = URL.createObjectURL(audioBlob)
      console.log("could not play audio")
      var audio = new Audio(audioURL)

      //need to make changes here
      if (playPromptAfter) {
        setCurrentResponseAudio(audioBlob)
      }
      else {
        setCurrentPromptAudio(audioBlob)
      }
      audio.play()

      //set character portrait to speaking
      setPortaitTalking(emotion)
      var duration = 1
      audio.addEventListener('loadedmetadata', function () {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        duration = audio.duration;
        console.log("Playing audio of duration: " + duration)


        var totalTime = (1000 * duration) + 400
        const timer = setTimeout(() => {

          //set character portrait to listening
          setPortaitListening(emotion)

          if (playPromptAfter) {
            //give pause between repsonse and prompt speech
            var totalTime2 = 800
            const timer2 = setTimeout(() => {
              fetchAndPlayPrompt(voiceClipSelectObj)
            }, totalTime2);
            return () => clearTimeout(timer2);
          }
          else {
            setDisableInteraction(false)
          }

        }, totalTime);
        return () => clearTimeout(timer);

      }, false);
    }
    else if (playPromptAfter) {
      fetchAndPlayPrompt(voiceClipSelectObj)
    }
    else {
      setDisableInteraction(false)
    }
  };

  const setPortaitListening = (emotion) => {
    //change character portrait based on emotion
    if (emotion === "happy") {
      setCurrentCharacterPortrait(2)
    }
    else if (emotion === "neutral") {
      setCurrentCharacterPortrait(0)
    }
    else if (emotion === "sad") {
      setCurrentCharacterPortrait(4)
    }
  }

  const setPortaitTalking = (emotion) => {
    if (emotion === "happy") {
      setCurrentCharacterPortrait(3)
    }
    else if (emotion === "neutral") {
      setCurrentCharacterPortrait(1)
    }
    else if (emotion === "sad") {
      setCurrentCharacterPortrait(5)
    }
  }




  //      Handler functions


  const handleInputChange = (evt) => {
    setUserInputText(evt);
  };

  const handleSubmit = () => {
    if (userInputText === "") {
      return
    }
    // set open conv to completed
    progressContext.setProgress({
      ...progressContext.progress,
      openConversation: true
    })

    console.log("\n ------------New User Input Submission--------- \n")
    console.log("handleSubmit() called \n userInputText: " + userInputText)

    var chatBotParams = { "userInput": userInputText, "convTopic": convTopic, "charResponseTopic": charOverallTopic, 
    "possibleTopics": possibleTopics, "mentionedTopics": mentionedTopics, "answeredTopics": answeredTopics, 
    "characterName": characterName, "userName":userName, "currentCharacterEmotion": getEmotionalState(characterEmotionPoints) }

    console.log("submitting Input to backend with chatBotParams: ")
    console.log(chatBotParams)

    // Making call to chatGTP
    let messagesToSend = convMessages
    let botResponse = ""
    if (convMessages.length % 2 === 0) {
      messagesToSend.push(systemMessage)
    }
    fetch('https://kyokos-friends-node-backend-zelhqvxj6q-uc.a.run.app/chatGTPResponse', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: messagesToSend,
          userInput: userInputText
        })
      }).then((response) => response.json())
        .then((data) => {
          let storedMessages = data.returnMessages
          storedMessages.push({ role: 'assistant', content: data.botResponse})
          setConvMessages(storedMessages)
          setConvMessages(storedMessages)
          botResponse = data.botResponse.substring(0, data.botResponse.indexOf("(")).trim();
          const translation = data.botResponse.substring(data.botResponse.indexOf("(") + 1, data.botResponse.indexOf(")")).trim();
          const transObj = {
            breakdown : [],
            formality : "",
            literal : "",
            semantic : translation
          }
          
          const starterChatbotData = {
            "aiPrompt": data.botResponse.substring(0, data.botResponse.indexOf("(")).trim(),
            "aiResponse": "blank",
            "answeredTopics": answeredTopics,
            "correction": "",
            "currentConvTopic": convTopic,
            "mentionedTopics": mentionedTopics,
            "possibleTopics": possibleTopics,
            "promptEmotion": "neutral",
            "promptTransObj": transObj,
            "responseConvTopic": "none",
            "responseEmotion": "neutral",
            "responseTransObj": console.log("null"),
            "emotionPointsChange": 0
            }
          console.log(data)
          
          processAiDialogue(starterChatbotData)
          
          // fetch and set word by word translation
          //getAndSetWordByWordTrans(botResponse)

    }).then(() => {
      getAndSetWordByWordTrans(botResponse);
    });

    // fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/submitInput', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(chatBotParams)
    // }).then((response) => response.json())
    //   .then((data) => {
    //     data.aiPrompt = botResponse
    //     processAiDialogue(data)
    // });
  };

  const start = () => {
    console.log("Hitting Start")
    console.log(recordState)
    setrecordstate(RecordState.START);
    setIsRecording(true)
    setDisableInteraction(true)
  };

  const stop = () => {
    console.log("Hitting Stop")
    console.log(recordState)
    setrecordstate(RecordState.STOP);
    setIsRecording(false)
    setDisableInteraction(false)
  };

  window.onload = () => {
    console.log("page is loaded")
  }

  //send audio data to back end to be transcribed on Stop
  const onStop = (audioData) => {
    var data = new FormData()
    data.append('audio_data', audioData.blob, 'audioInput')
    setLoading(true)
    setrecordstate(RecordState.STOP);
    setMicEnabled(true)
    setDisableInteraction(false)
    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceInputTranscribe', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
      .then((data) => {
        setLoading(false)
        console.log(data)
        if (data && typeof data === 'string') {
          setUserInputText(data)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  };


  /* ##############           HTML and components for actual page               #############*/

  return (

    <div style={{cursor: loading ? 'progress' : ''}}>
        <div>
          <div class="behindEnvironment">
            <img class="backgroundEnvironment" src={backgroundImage} alt="background"></img>
            <img style={{right: "25vw", top: "0.5vw"}} class="character" src={Kyokos[currentCharacterPortrait]} alt="character"></img>
            <img class="coverEnvironment" src={tableCover} alt="cover bg"></img> 
            <div class="userInputDiv">
              <h3>{userName}</h3>
              <input
                onChange={(c) => handleInputChange(c.target.value)}
                value={userInputText}
                type="text"
                placeholder=""
                tabIndex="-1"
                style={{cursor: loading ? 'progress' : '',
                height: "4vw", position: "absolute", top: "4vw", right: "1.14vw"
              }}
              />
              {recordState !== RecordState.STOP
                ? <button style={{cursor: loading ? 'progress' : '', position: "absolute", top: "8.3vw", right: "18vw"}} class="characterOutputButtons" onClick={stop}>Stop Recording</button>
                : micEnabled ? <button style={{cursor: loading ? 'progress' : '', position: "absolute", top: "8.3vw", right: "18vw"}} class="characterOutputButtons" disabled={disableInteraction} onClick={start}>Speak</button>
                : <button style={{cursor: loading ? 'progress' : '', position: "absolute", top: "8.3vw", right: "18vw"}} class="characterOutputButtons" disabled={disableInteraction} onClick={start}>Enable Mic</button>
              }
              <button style={{cursor: loading ? 'progress' : '', position: "absolute", top: "8.3vw"}} class="characterOutputButtons" disabled={disableInteraction} onClick={handleSubmit}>Send</button>
              <AudioReactRecorder canvasWidth="0"
                canvasHeight="0"
                autoPlay
                state={recordState} onStop={onStop} />
            </div>
            <div class="userInputCOVERDiv">
            </div>
            <div class="characterOutputDiv">
              <h3>{characterName}</h3>
              <HoverTranslation prompt={currentCharacterPrompt} response={currentResponse} responseTranslation={currentRespTransObj} promptTranslation={currentPromptTransObj}
               promptAudio={currentPromptAudio} responseAudio={currentResponseAudio} isAudioDisabled={disableInteraction} disableAudioFunction={setDisableInteraction}
               promptFurigana={promptFurigana} responseFurigana={responseFurigana} isFuriganaEnabled={showFurigana} isSemanticTranslationEnabled={showSemanticTranslation}
               promptWBW={wordByWordTrans} responseWBW={[]} isWBWTransEnabled={showWordTranslation}/>
            </div>
            {/* <div class="suggestionsDiv" onMouseEnter={e => {
                     setSuggestionsDisplay({display: 'block'});
                 }}
                 onMouseLeave={e => {
                  setSuggestionsDisplay({display: 'none'})
                 }}>
              <h3>Suggested Remarks</h3>
              <UserInputSuggestions conversationTopic={convTopic} responseConvTopic={charOverallTopic} possibleTopicList={possibleTopics} 
              suggestions={userInputSuggestionsArrays} suggestionTranslations={userInputSuggestionTranslationArrays} suggestionsFurigana={userInputSuggestionFuriganaArrays}
              fuqSuggestions={followUpQSuggestionsArrays} fuqSuggestionsTranslations={fuqSuggestionsTranslationsArrays} fuqFurigana={fuqSuggestionFuriganaArrays}
              promptSuggestions={promptSuggestionsArrays} promptSuggestionsTranslations={promptSuggestionTranslationArrays} promptFurigana={promptSuggestionFuriganaArrays}
              isAudioDisable={disableInteraction} disableAudioFunction={setDisableInteraction} suggDisplay={suggestionsDisplay} whichSuggestionsSetting={suggestionsSetting}
              isFuriganaEnabled={showFurigana} isSemanticTranslationEnabled={showSemanticTranslation}/>
            </div> */}
          </div>
          <div class="lowerCover">
          </div>
        </div>
      <div class="convoSideBarDiv">
        <ConvoSidebar settingsParams={{suggestions: {suggestionsSettingTypes: suggestionsSettingTypes, suggestionsSetting: suggestionsSetting, setSuggestionsSetting: changeSuggestionSetting},
          translations: {translationTypes: translationTypes,translationTypeVals: [showSemanticTranslation, showWordTranslation, showFurigana], translationTypeSetters: [setShowSemanticTranslation, setShowWordTranslation, setShowFurigana]}}} 
          DemoInfoModalOpen={DemoInfoModalOpen} setDemoInfoModalOpen={setDemoInfoModalOpen} 
          setName={setUserName} currName={userName}
          class="convoSideBarDiv"></ConvoSidebar> 
      </div> 
    </div>
  );
};

export default Recorder;
