// bg
const microphone = require("../bg/microphone.jpeg");
const livingRoomBackground = require("../../../styles/backgroundImages/neighborhoodEntrance.jpg")
const entrance = require("../bg/entrance.jpeg");
// bgm
const take = require("../bgm/menuMusic.mp3");
// speakers
const b = "Block";
const daichi = "Daichi"
const ren = "Ren"
// sprites
const bn = require('../../../styles/Kyoko/neutralListening.png')
const bh = require('../../../styles/Kyoko/neutralListening.png')
const bp = require('../../../styles/Kyoko/neutralListening.png')

const neutral_listenR = require('../../../styles/Ren/neutralListening.png')
const neutral_speakingR = require('../../../styles/Ren/neutralSpeaking.png')
const neutral_listenD = require('../../../styles/Daichi/neutralListening.png')
const neutral_speakingD = require('../../../styles/Daichi/neutralSpeaking.png')
const happy_listenD = require('../../../styles/Daichi/happyListening.png')
const happy_speakingD = require('../../../styles/Daichi/happySpeaking.png')
//const neutral_talk from '../../styles/Kyoko/neutralSpeaking.png'

let introductionsLessonStory = [
  {
    bg: livingRoomBackground,
    bgm: take,
    sprite: happy_speakingD,
    speaker: daichi,
    text: "Hello again. Are you ready to start your work with the residents?"
  },
  {
    sprite: neutral_speakingD,
    text: "Meeting them would be a good place to start, so why don't we go over the basic of how to introduce yourself?"
  },
  {
    text: "I'll go over a few grammar points that'll be useful for meeting new people. Let's start with making basic sentences with 'は' (wa) and 'です' (desu)."
  },
  {
    text:
      "'は' (wa) can roughly translate to 'regarding' and is used to indicate the topic of the sentence. 'です' (desu) can roughly translate to 'it is'."
  },
  {
    text:
      "You can make a basic statement with the formula 'X は Y です' = 'X is Y.' This translates literally to 'Regarding X, it is Y' "
  },
  {
    text:
      "An example of this would be '私 は 大地 です' \n 私 (watashi) means 'myself' and 大地 (Daichi) is my name 'Daichi'. So '私 は 大地 です' literally means 'Regarding myself, it is Daichi.', or less literally, 'I am Daichi.'"
  },
  {
    text:
      "How would you say your name using 'X は Y です' ?"
  },
  {
    text:
      "Next let's go through the 'か' (ka) particle. \n The 'か' (ka) particle can be added to the end of statements to make a question. In these cases, 'か' acts like question mark: '?' \n So 'X は Y です か' = 'Is X Y?' or more literally, 'Regarding X, is it Y?'"
  },
  {
    text:
      "A way you could use this when meeting someone new is '名前 は 何 です か' \n 名前 (namae) means 'name' and 何 (nani on it's own but just nan here) means 'what'. \n Literally, this means 'Regarding name, what is it?'. But it's used as 'What is your name?'"
  },
  {
    text:
      "The 'your' part of '名前 は 何 です か' [What is your name?] is implied by the context of the situation (meeting someone new). Because you are asking '名前 は 何 です か' directly to someone, they'll know you're asking about their name."
  },
  {
    text:
    "In Japanese, parts of sentences are often dropped if they are implied. \n For example, I could say '私 は 大地 です' to introduce myself, but just '大地 です' would be understood to mean the same thing if I was meeting someone new."
  },
  {
    text:
      "Now that you know the basics, let's try a more comprehensive demonstration."
  },
  {
    sprite: happy_speakingD,
    text:
      "Ah- perfect timing, I see Ren coming down the road."
  },
  {
    spriteRight: neutral_listenR,
    text:
      "Ren and I will demonstrate what meeting someone new would look like."
  },
  {
    text:
      "こんにちは! \n  \n [hello]",
      soundEffect: require("../sounds/Daichiこんにちは.wav")
  },
  {
    sprite: happy_listenD,
    spriteRight: neutral_speakingR,
    speaker: ren,
    text:
      "こんにちは. \n  \n [hello]",
      soundEffect: require("../sounds/Renこんにちは.wav")
  },
  {
    sprite: happy_speakingD,
    spriteRight: neutral_listenR,
    speaker: daichi,
    text:
      "私 は 大地 です. 名前 は 何 です か \n  \n [My name is Daichi. What is your name?]",
      soundEffect: require("../sounds/Daichi私は大地です.wav")
  },
  {
    sprite: happy_listenD,
    spriteRight: neutral_speakingR,
    speaker: ren,
    text:
      "名前 は 蓮 です. 初めまして. \n  \n [My name is Ren. Nice to meet you.]",
      soundEffect: require("../sounds/Ren名前は蓮です.wav")
  },
  {
    sprite: happy_speakingD,
    spriteRight: neutral_listenR,
    speaker: daichi,
    text:
      "よろしく \n  \n [Nice to meet you.]",
      soundEffect: require("../sounds/Daichiよろしく.wav")
  },
  {
    text:
      "Thanks Ren!"
  },
  {
    sprite: happy_listenD,
    spriteRight: neutral_speakingR,
    speaker: ren,
    text:
      "またね \n \n [See ya]",
      soundEffect: require("../sounds/Renまたね.wav")
  },
  {
    sprite: happy_speakingD,
    spriteRight: "",
    speaker: ren,
    text:
      "I forgot to mention, but よろしく (yoroshiku) and 初めまして (hajimemashite) both essentially mean 'Nice to meet you' when said to someone you're meeting for the first time. \n And 'またね' is an informal way of saying goodbye. "
  },
  {
    text:
      "That about covers the basics for introductions. Kyoko mentioned she would be willing to help you with your language learning, why don't you meet up with her and practice what we learned?"
  },
  {
    text:
      "I have some other work to get to, see you around!"
  }
];

// The code below is to set undefined properties to the last defined property.
// It is optional and based on preference, so feel free to add or remove any function calls.

setFutureProperties("bg");
setFutureProperties("bgm");
setFutureProperties("speaker");
setFutureProperties("sprite");
setFutureProperties("spriteLeft");
setFutureProperties("spriteRight");

function setFutureProperties(key) {
  let cache = "";
  for (let obj of introductionsLessonStory) {
    if (obj[key] || obj[key] === "") {
      cache = obj[key];
    } else {
      obj[key] = cache;
    }
  }
}
export default introductionsLessonStory;
