import React, { useState, useContext } from 'react';
import './HomeComponents.css';
import NoteModal from "../ConversationComponents/Modal/NoteModal";
import VocabModal from "../ConversationComponents/Modal/VocabModal";
import TasksModal from "../ConversationComponents/Modal/TasksModal";
import ConversationModal from "../ConversationComponents/Modal/ConversationModal";
import ConversationModalSmallTalk from "../ConversationComponents/Modal/ConversationModalSmallTalk";
import ConversationModalAboutYou from "../ConversationComponents/Modal/ConversationModalAboutYou";
import OpenConversationModal from "../ConversationComponents/Modal/OpenConversationModal";
import ArtifactModal from "../ConversationComponents/Modal/ArtifactModal";
import ArtifactModalAudioMessage from "../ConversationComponents/Modal/ArtifactModalAudioMessage";
import ExConvsModal from "../ConversationComponents/Modal/ExConvsModal";
import DemoIntro from "../ConversationComponents/Modal/DemoIntro";
import Sound from "react-sound";
import menuMusic from '../../CutsceneData/story/bgm/menuMusic.mp3'; 
import { ProgressContext } from '../../App';

import diaryIcon from "./diaryIcon.png"
import diaryIconAlt from "./diaryIconAlt.png"
import diaryIconLocked from "./diaryIconLocked.png"
import phoneIcon from "./phoneIcon.png"
import phoneIconAlt from "./phoneIconAlt.png"
import phoneIconLocked from "./phoneIconLocked.png"
import stickyNoteIcon from "./stickyNoteIcon.png"
import lockIconDark from "./LockIconDark.png"
import lockIconWhite from "./LockIconWhite.png"
import newStar from "./yellowStar.png"
import stickyNoteIconGreen from "./stickyNoteIconGreen.png"

function LessonContent() {
  const progressContext = useContext(ProgressContext);
  const [DemoInfoModalOpen, setDemoInfoModalOpen] = useState(false);
    const [TasksModalOpen, setTasksModalOpen] = useState(false);
    const [NotesModalOpen, setNotesModalOpen] = useState(false);
    const [VocabModalOpen, setVocabModalOpen] = useState(false);
    const [ConversationModalOpen, setConversationModalOpen] = useState(false);
    const [ConversationModalSmallTalkOpen, setConversationModalSmallTalkOpen] = useState(false);
    const [ConversationModalAboutYouOpen, setConversationModalAboutYouOpen] = useState(false);
    const [OpenConversationModalOpen, setOpenConversationModalOpen] = useState(false);
    const [ArtifactModalOpen, setArtifactModalOpen] = useState(false);
    const [ExConvsModalOpen, setExConvsModalOpen] = useState(false);
    const sidebarItems = ['Chapter 0'];
    const artifact1Unlocked = false;
    

    return (
        <div >
          <div className="container">
            <div className="sidebar">
              <ul>
                {sidebarItems.map((item, index) => <li key={item}><button style={{ backgroundColor: index === 0 ? '#8a8a8a': '#fff'}}> {index !== 0 && <img src={lockIconDark}/>} {item}</button></li>)}
              </ul>
            </div>
            <div className="content">
              <div className="section">
                <h2>Lesson Material</h2>
                <div className="button-group">
                  <button onClick={() => {setTasksModalOpen(true); }}>
                    Tasks {!progressContext.progress.introductionLesson && <img src={newStar}/>}</button>
                  <button onClick={() => {setExConvsModalOpen(true); }}>
                    Lessons 
                    {!progressContext.progress.introductionLesson && <img src={newStar}/>}</button>
                    <button onClick={() => {setVocabModalOpen(true)}} disabled={!progressContext.progress.introductionLesson} className={!progressContext.progress.introductionLesson ? 'disabled' : ''}>
                    Vocabulary {!progressContext.progress.introductionLesson && <img src={lockIconWhite}/>}
                    {progressContext.progress.introductionLesson && !progressContext.progress.introductionGuidedConv && <img src={newStar}/>}</button>
                  <button onClick={() => {setNotesModalOpen(true); }} disabled={!progressContext.progress.introductionLesson} className={!progressContext.progress.introductionLesson ? 'disabled' : ''}>
                    Grammar Notes {!progressContext.progress.introductionLesson && <img src={lockIconWhite}/>}
                    {progressContext.progress.introductionLesson && !progressContext.progress.introductionGuidedConv && <img src={newStar}/>}</button>
                    <button onClick={() => {setDemoInfoModalOpen(true); }}>Help</button>
                </div>
              </div>
              <div className="section">
                <h2>Conversations</h2>
                <div className="button-group">
                    <button onClick={() => {setConversationModalOpen(true) }} disabled={!progressContext.progress.introductionLesson} className={!progressContext.progress.introductionLesson ? 'disabled' : ''}>
                      Introductions {!progressContext.progress.introductionLesson && <img src={lockIconWhite}/>}
                      {progressContext.progress.introductionLesson && !progressContext.progress.introductionGuidedConv && <img src={newStar}/>} </button>
                    <button onClick={() => {setConversationModalSmallTalkOpen(true); }} disabled={!progressContext.progress.openConversation} className={!progressContext.progress.openConversation ? 'disabled' : ''}>Small Talk {!progressContext.progress.openConversation && <img src={lockIconWhite}/>}</button>
                <div style={{height: "auto", borderTop: "3px #c2c0c0 solid", marginTop: "1vw"}}>
                  <div  className="button-group">
                  <button onClick={() => {setOpenConversationModalOpen(true); }} disabled={!progressContext.progress.introductionGuidedConv} className={!progressContext.progress.introductionGuidedConv ? 'disabled' : ''}>Open Conversation {!progressContext.progress.introductionGuidedConv && <img src={lockIconWhite}/>}</button>
                  </div>
                  </div>
                </div>
              </div>
              <div style={{minHeight: '40%'}} className="section">
                <h2>Artifacts</h2>
                <div className="button-group">
                  <div className="artifactButton">
                    {progressContext.progress.openConversation ? <img src={phoneIcon}/> : <img src={phoneIconLocked}/>}
                   <button disabled={!progressContext.progress.openConversation} className={!progressContext.progress.openConversation ? 'disabled' : ''} onClick={() => {setArtifactModalOpen(true); }} >
                     Kyoko #1 </button>
                  </div>
                  {/* <div className="artifactButton">
                   <img src={stickyNoteIconGreen}/>
                   <button>Ren #1</button>
                  </div>
                  <div className="artifactButton">
                   <img src={diaryIconAlt}/>
                   <button>Yuto #1</button>
                  </div>
                  <div className="artifactButton">
                   <img src={phoneIconLocked}/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {DemoInfoModalOpen && <DemoIntro setOpenModal={setDemoInfoModalOpen} />}
          {TasksModalOpen && <TasksModal setOpenModal={setTasksModalOpen} />}
          {NotesModalOpen && <NoteModal setOpenModal={setNotesModalOpen} />}
          {VocabModalOpen && <VocabModal setOpenModal={setVocabModalOpen} />}
          {ConversationModalOpen && <ConversationModal setOpenModal={setConversationModalOpen} />}
          {ConversationModalSmallTalkOpen && <ConversationModalSmallTalk setOpenModal={setConversationModalSmallTalkOpen} />}
          {ConversationModalAboutYouOpen && <ConversationModalAboutYou setOpenModal={setConversationModalAboutYouOpen} />}
          {OpenConversationModalOpen && <OpenConversationModal setOpenModal={setOpenConversationModalOpen} />}
          {ArtifactModalOpen && <ArtifactModalAudioMessage setOpenModal={setArtifactModalOpen} />}
          {ExConvsModalOpen && <ExConvsModal setOpenModal={setExConvsModalOpen} />}
        </div>
      );
    }

export default LessonContent;
