import React from "react";
import "./Modal.css";
import kyokoFace from "./charFaceShots/KyokoFaceShot.png"
import yutoFace from "./charFaceShots/YutoFaceShot.png"
import DaichiFace from "./charFaceShots/DaichiFaceShot.png"
import RenFace from "./charFaceShots/RenFaceShot.png"
import { Link } from 'react-router-dom';


function  ConversationModalAboutYou({ setOpenModal, translationSettings, suggestionsSettings }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Small Talk</h1>
        </div>
          <ul className="conversationCharacterList">
            <li className="charFaceShot">
            <Link to="/guidedConvDemoAboutYou" style={{textDecoration: "none"}}>
              <div onClick={console.log("click")}>
              <img  src={kyokoFace}/>
              <div style={{background: "#323232"}}><b>Kyoko</b></div>
                
                </div>
            </Link>
            </li>
          </ul>
      </div>
    </div>
  );
}

export default  ConversationModalAboutYou;