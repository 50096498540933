import React, { useState } from "react";
import Modal from "../Modal/Modal";
import NoteModal from "../Modal/NoteModal";
import TasksModal from "../Modal/TasksModal";
import VocabModal from "../Modal/VocabModal";
import DemoInfo from "../Modal/DemoInfo";
import LeaveModal from "../Modal/LeaveModal";
import TeachingPractice from "../Modal/TeachingPractice";
import { Link } from 'react-router-dom';
import "./ConvoSidebar.css";


const ConvoSidebar = ({settingsParams, DemoInfoModalOpen, setDemoInfoModalOpen, setName, currName, suggestionsEnabled=false}) => {

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [TasksModalOpen, setTasksModalOpen] = useState(false);
    const [NotesModalOpen, setNotesModalOpen] = useState(false);
    const [VocabModalOpen, setVocabModalOpen] = useState(false);
    const [LeaveModalOpen, setLeaveModalOpen] = useState(false);

  return (
    <div>
        {/* <Link to="/chapterNav" style={{textDecoration: "none"}}><button className="sideBarbutton">Leave</button></Link> */}
        <button className="sideBarbutton" onClick={() => {setLeaveModalOpen(true); }}>Leave</button>
        <button className="tasksSideB" onClick={() => {setDemoInfoModalOpen(true); }}>Help</button>
        <button className="notesSideB" onClick={() => {setNotesModalOpen(true); }}>Notes</button>
        <button className="vocabSideB" onClick={() => {setVocabModalOpen(true); }}>Vocab</button>
        <button className="settingsSideB" onClick={() => {setSettingsModalOpen(true); }}>Options</button>

        {LeaveModalOpen && <LeaveModal setOpenModal={setLeaveModalOpen} />}
        {settingsModalOpen && <Modal suggestionsSettings={settingsParams.suggestions} translationSettings={settingsParams.translations} setOpenModal={setSettingsModalOpen} suggestionsEnabled={suggestionsEnabled}/>}
        {DemoInfoModalOpen && <DemoInfo setOpenModal={setDemoInfoModalOpen} />}
        {/* {DemoInfoModalOpen && <TeachingPractice setName={setName} currName={currName} setOpenModal={setDemoInfoModalOpen} englishPhrase={'My name is Carlos'} pronunciation={'namae wa Carlos desu'} correctAnswer={'名前はカルロスです'}/>} */}
        {NotesModalOpen && <NoteModal setOpenModal={setNotesModalOpen} />}
        {VocabModalOpen && <VocabModal setOpenModal={setVocabModalOpen} />}
    </div>
  );
};

export default ConvoSidebar;