import React, { useState } from "react";
import KeyHandler, { KEYDOWN, KEYUP } from "react-key-handler";
import LeaveModal from "../../Components/ConversationComponents/Modal/LeaveModal";
import { Link } from 'react-router-dom';

function MenuButtons(props) {
  const [LeaveModalOpen, setLeaveModalOpen] = useState(false);

  function handleToggles(event, value, toggle) {
    return <KeyHandler keyEventName={event} keyValue={value} onKeyHandle={toggle} />;
  }

  return (
    <div>
      <div className="cutsceneContainer menu-buttons-container">
        <div className="menu-buttons">
          {handleToggles(KEYDOWN, " ", props.toggleTextBox)}
          {handleToggles(KEYDOWN, "Control", props.setNextFrame)}
          {handleToggles(KEYUP, "Enter", props.setNextFrame)}
          {/* {props.isSkipping ? (
            <button onClick={props.stopSkip}>Stop</button>
          ) : (
            <button onClick={props.startSkip}>Skip</button>
          )}

          <button onClick={props.toggleSaveMenu}>{props.saveMenuShown ? "Hide Saves" : "Save"}</button>
          <button onClick={props.toggleLoadMenu}>{props.loadMenuShown ? "Hide Loads" : "Load"}</button> */}
          {/* <Link to="/chapterNav" style={{textDecoration: "none"}}><button>Leave</button></Link> */}
          <button onClick={() => {props.setLeaveMenuDisplay(true); }}>Leave</button>
          <button onClick={props.toggleConfigMenu}>{props.configMenuShown ? "Hide Config" : "Options"}</button>
          {/* <button onClick={props.toggleFullscreen} style={{ float: "right" }}>
            Fullscreen
          </button> */}
        </div>
      </div>
      {props.leaveMenuShown && <LeaveModal setOpenModal={props.setLeaveMenuDisplay} />}
    </div>
  );
}

export default MenuButtons;
