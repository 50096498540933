import React, {useState } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import "./Modal.css";

function DemoInfo({ setOpenModal, translationSettings, suggestionsSettings, setName, currName}) {
  const [recordState, setrecordstate] = useState(RecordState.STOP);
  const [userInputText, setUserInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);

  const onStop = (audioData) => {
    var data = new FormData()
    data.append('audio_data', audioData.blob, 'audioInput')
    setLoading(true)
    setrecordstate(RecordState.STOP);
    setMicEnabled(true)
    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceInputTranscribe', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
      .then((data) => {
        setLoading(false)
        console.log(data)
        if (data && typeof data === 'string') {
          setUserInputText(data)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  };

  const start = () => {
    console.log("Hitting Start")
    console.log(recordState)
    setrecordstate(RecordState.START);
    setIsRecording(true)
  };

  const stop = () => {
    console.log("Hitting Stop")
    console.log(recordState)
    setrecordstate(RecordState.STOP);
    setIsRecording(false)
  };
  const handleInputChange = (evt) => {
    setUserInputText(evt);
  };

  return (
    <div style={{cursor: loading ? 'progress' : ''}} className="modalBackground">
      <div style={{height: "90vh"}} className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div style={{marginTop: "-5vh", top: "7vh"}} className="title" >
          <h1>Conversation Instructions</h1>
        </div>
        <div  style={{border: "solid gray", marginTop: "7vh", overflowY: "scroll"}}>
        <h3 style={{marginTop: "2vh"}}>Speaking</h3>
        <div  style={{textAlign: "left", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <a >1. Click "Enable Mic" and wait for button to say "Speak"</a>
          <br/> 
          <a >2. Click on the "Speak" button to begin recording your input and press it again ("Stop") when you are done talking.</a>
          <br/> 
          <a >3. If your speech was picked up it should appear as text after a few moments.</a>
          <br/> 
          <a >4. To submit your input, press the "Send" button once.</a>
          <br/> 
           {/* <br/> 
          <a> You can test out speaking by setting your name below. Since the text-to-speech is expecting Japanese, you may want to set choose a name with clear syllables that can be recognized by the system easily. ノア (Noah) and さくら (Sakura) are good examples.</a> */}
        </div>
        {/* <div style={{position: "unset", margin: "auto", height: "fit-content"}} class="userInputDiv">
              <h3> Enter Name: {currName}</h3>
              <input
                onChange={(c) => handleInputChange(c.target.value)}
                value={userInputText}
                type="text"
                placeholder=""
                style={{cursor: loading ? 'progress' : '', height: "3vw"}}
              />
              {recordState !== RecordState.STOP
                ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" onClick={stop}>Stop Recording</button>
                : micEnabled ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Speak</button>
                : <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Enable Mic</button>
              }
              <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={() => setName(userInputText)} >Set Name</button>
              <AudioReactRecorder canvasWidth="0"
                canvasHeight="0"
                autoPlay
                state={recordState} onStop={onStop}
                style={{height: 0}} />
            </div> */}
            <br/> 
        <h3>Understanding Resident Dialogue</h3>
        <div  style={{textAlign: "left", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <b style={{fontSize: "1.3vw", color: "white"}}>Replay Speech</b> <a>: Click on the resident's dialogue to replay the audio of them saying that dialogue.</a>
          <br/>
          <b style={{fontSize: "1.3vw", color: "white"}}>Display Translations</b> <a>: Hover over resident dialogue to display translations and/or furigana for that text</a>
          <br/>
          <b style={{fontSize: "1.3vw", color: "white"}}>Translation Settings</b> <a>: To change what translations show up and if furigana shows up, click on the "Options" button in the sidebar and select what you want to show up when you hover over the resident's dialogue.</a>
        </div>
        <br/> 
        <h3 >Suggested User Input (Guided Conversations only)</h3>
        <div  style={{textAlign: "left", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <b style={{fontSize: "1.3vw", color: "white"}}>Display Suggestions</b> <a>: Hover over the "Suggested Remarks" box to display suggested phrases you can say to the resident in the context of your current conversation.</a>
          <br/>
          <b style={{fontSize: "1.3vw", color: "white"}}>Suggestion Settings</b> <a>: To change what kind of suggestions selection show up, click on the "Options" button in the sidebar and select your desired suggestion setting.</a>
          <br/>
          <b style={{fontSize: "1.3vw", color: "white"}}>Play Suggestion Audio</b> <a>: Click on a specific suggestion phrase text to hear how to say that phrase</a>
        </div>
        </div>

      </div>
    </div>
  );
}

export default DemoInfo;