import React from "react";
import "./Modal.css";
import RadioButtons from "../InputListComponents/RadioButtons.js";
import CheckBoxes from "../InputListComponents/CheckBoxes.js";

function Modal({ setOpenModal, translationSettings, suggestionsSettings, suggestionsEnabled }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Interface Options</h1>
        </div>
        
            <div className="optionsContainer">
              {suggestionsEnabled ? 
              <div style={{height: "auto"}}> 
              <h3 className="settingsSubtitleFirst">Suggestions Given</h3>
              <RadioButtons radioList={suggestionsSettings.suggestionsSettingTypes} currentRadioValue={suggestionsSettings.suggestionsSetting} setRadioValue={suggestionsSettings.setSuggestionsSetting}/>
              </div>
            :<div style={{height: 0}}></div>
            }
              
              <h3 className="settingsSubtitle">Hover Translation Information</h3>
              <CheckBoxes checkboxLabelList={translationSettings.translationTypes} isCheckedList={translationSettings.translationTypeVals} setAsCheckedList={translationSettings.translationTypeSetters} />
            </div>
      </div>
    </div>
  );
}

export default Modal;