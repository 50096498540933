import React, {useState} from 'react';
//import StartMenuBackground from './StartMenuBackground.png'
import menuBackground from './menuBackground.jpg'
import './StartMenu.css';
import KFLogo from '../../Components/HomeComponents/kfFullLogo.png'
import { Link } from 'react-router-dom';
import SetPlayerName from '../../Components/ConversationComponents/Modal/setPlayerName';

function StartMenu() {
  const [showSetNameModal, setShowSetNameModal] = useState(false);
    return (
        <div>
          <img class="backgroundImg" src={menuBackground} alt="background"></img>
          <img class="logo" src={KFLogo}></img>
          <button className="newGameButton" onClick={()=> {setShowSetNameModal(true)}}>New Game (Story Mode)</button>
          <a href="https://kyokosfriends-reactfe-zelhqvxj6q-uc.a.run.app"><button className="featureDemo" onClick={()=> {window.location("NewSite.com")}}>Features Demo</button></a>

          {showSetNameModal && <SetPlayerName setOpenModal={setShowSetNameModal}/>}
        </div>
      );
    }

export default StartMenu;