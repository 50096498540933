import React from "react";
import "./Modal.css";
import { useNavigate } from 'react-router-dom';

function NoteModal({ setOpenModal, translationSettings, suggestionsSettings }) {
  let navigate = useNavigate(); 
  const routeChange = (path) =>{
    navigate(path);
  }
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Lessons</h1>
        </div>
          <div style={{marginTop: "9vw"}}>
            <button className="ExampleConvButton" onClick={()=>{routeChange('/introductionsLesson')}}>Introductions</button>
          </div>
          
      </div>
    </div>
  );
}

export default NoteModal;