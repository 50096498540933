import React from 'react';
import './HomeComponents.css';
import LessonContent from "./LessonContent"
import DEMOLessonContent from "./DEMOLessonContent"
import LessonContentChapter6 from "./LessonContentChapter6"
import Friendships from "./Friendships/Friendships"
import profileLogo from './ProfileIcon.png'
import KFLogo from './kfFullLogo.png'

import ksbutton from '../../styles/KSbutton.png'

function MainNavPage() {
    return (
        <div >
          <nav>
          <img className="KFIcon" src={KFLogo}/> 
            <ul>
              <li><a href="#">DEMO</a></li>
            </ul>
          </nav>
          <DEMOLessonContent />

        </div>
      );
    }

export default MainNavPage;