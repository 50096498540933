import React, {useState} from "react";
import { Markup } from 'interweave';
import "./styles.css";

const HoverTranslationSuggestion = ({ phrase, phraseTranslation, convTopic, furigana, index, 
  innerIndex, isFuqPhrase, isAudioDisabled, disableAudioFunction, isFuriganaEnabled, isSemanticTranslationEnabled}) => {

  const [style, setStyle] = useState({display: 'none'});
  const [hoverState, setHoverState] = useState(0);
  const [phraseAudio, setPhraseAudio] = useState("");
  const [currAudioPhrase, setCurrAudioPhrase] = useState("");

  const getAndPlayAIVoiceFile = (dialog) => {
    console.log("Hover translation AI VOICE FETCH")
    // fetch('https://voicevoxengine-zelhqvxj6q-uc.a.run.app/audio_query?text='+ dialog +'&speaker=6', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }).then((response) => response.json())
    //     .then((VVSynthesisData_fetched) => {
    //       console.log("VOICEVOX FIRST CALL", VVSynthesisData_fetched);
    //       fetch('https://voicevoxengine-zelhqvxj6q-uc.a.run.app/synthesis?speaker=6&enable_interrogative_upspeak=true', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(VVSynthesisData_fetched)
    //       }).then((response) => response.blob()).then((data) => playPhraseAudio(data)).catch((error) => {
    //         console.log("axios error:", error);
    //       });
    //     });
    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceVoxVoiceBlob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phrase: dialog,
          speaker: '16'
        })
      }).then((response) => response.blob()).then((data) => playPhraseAudio(data)).catch((error) => {
            console.log("axios error:", error);
          });
    }

  const getAndPlayVoiceFile = (phraseString) => {
    if (currAudioPhrase !== phraseString) {  
      let promptOrResponse = "response"
      console.log(convTopic)
      if (isFuqPhrase || convTopic === "none") {
        promptOrResponse = "prompt"
      }
      const mp3ChoiceParams = { "promptOrResponse": promptOrResponse, "dialog": phraseString, "convtopic": convTopic, "emotion": "neutral", "character": "京子" }
      fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getAiVoice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(mp3ChoiceParams)
      }).then((response) => response.blob()).then((data) => {
        if(data !== null && data.size > 100 && isAudioDisabled === false) {
          console.log("voice FILE FOUND in GCP")
          setCurrAudioPhrase(phraseString)
          setPhraseAudio(data)
          playPhraseAudio(data)
        }
        else {
          getAndPlayAIVoiceFile(phraseString)
        }
      }).catch((error) => {
        console.log("axios error:", error);
      });
    }
    else {
      console.log("Same as curr string")
      console.log(currAudioPhrase, phraseString)
      playPhraseAudio(phraseAudio)
    }
  }

  const playPhraseAudio = (audioBlob) =>
  {
    console.log("User clicked on phrase!! to play translation")
    console.log(audioBlob)
    if(audioBlob !== null && audioBlob.size > 100 && isAudioDisabled === false) {
        try {
            disableAudioFunction(true)
            var audioURL = URL.createObjectURL(audioBlob)
            var audio = new Audio(audioURL)
            audio.play()
            var duration = 1
            audio.addEventListener('loadedmetadata', function () {
                duration = audio.duration;
                console.log("Playing audio of duration: " + duration)

                var totalTime = (1000 * duration) + 400
                const timer = setTimeout(() => {
                //set character portrait to listening
                //setPortaitListening(emotion)
                disableAudioFunction(false)
                }, totalTime);
                return () => clearTimeout(timer);

            }, false);
          }
          catch (e){
            disableAudioFunction(false)
            console.log("could not play audio")
            console.error(e)
          }
    }
    else {
      console.log(isAudioDisabled)
    }
  }

  return (
            <div onMouseEnter={e => {
              setHoverState(1);
              console.log(phraseTranslation);
              }}
              onMouseLeave={e => {
                  setHoverState(0);
              }}
              onClick={() => getAndPlayVoiceFile(phrase)}>
              
              {hoverState === 1 && furigana && isFuriganaEnabled ? <div>  <Markup content={furigana} /> </div> 
                        : <div className="noFuriganadiv"> <a>{phrase}</a> </div>}

            {hoverState === 1 && isSemanticTranslationEnabled && phraseTranslation !== null ?
            <div>
              {phraseTranslation["semantic"]}
            </div> :
             <div> </div> 
            }

            </div>
  );
};

export default HoverTranslationSuggestion;
