import React, {useState } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import "./Modal.css";
import backIcon from "../../HomeComponents/backIcon.png"

function TeachingPractice({ setOpenModal, englishPhrase, pronunciation, correctAnswer, nextDialog, prevDialog}) {
  const [recordState, setrecordstate] = useState(RecordState.STOP);
  const [userInputText, setUserInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);
  const [wrongInput, setWrongInput] = useState(false);

  function checkAnswer (input) {
    console.log("Hitting Stop")
    console.log(recordState)
    setrecordstate(RecordState.STOP);
    setIsRecording(false)
    if(input.replace(/\s/g, "")===correctAnswer){
      nextDialog()
    }
    else{
      setWrongInput(true)
    }
  };

  const onStop = (audioData) => {
    var data = new FormData()
    data.append('audio_data', audioData.blob, 'audioInput')
    setLoading(true)
    setrecordstate(RecordState.STOP);
    setMicEnabled(true)
    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceInputTranscribe', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
      .then((data) => {
        setLoading(false)
        console.log(data)
        if (data && typeof data === 'string') {
          setUserInputText(data)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  };

  const start = () => {
    console.log("Hitting Start")
    console.log(recordState)
    setrecordstate(RecordState.START);
    setIsRecording(true)
  };

  const stop = () => {
    console.log("Hitting Stop")
    console.log(recordState)
    setrecordstate(RecordState.STOP);
    setIsRecording(false)
  };
  const handleInputChange = (evt) => {
    setUserInputText(evt);
  };

  return (
    <div style={{cursor: loading ? 'progress' : ''}} className="modalBackground">
      <div style={{height: "36vh", width: "50vw", paddingBottom: 0}} className="modalContainer">
        <div style={{justifyContent: 'flex-start'}} className="titleCloseBtn">
          <button
            onClick={() => {
              prevDialog();
            }}
          >
            <img style={{height: '4vh'}} src={backIcon}></img>
          </button>
        </div>

        <div >
        <h3 style={{textAlign: 'center'}} >Say "{englishPhrase}" in Japanese</h3>
        <div  style={{textAlign: "center", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <a> {pronunciation}</a>
        </div>
        <div style={{position: "unset", margin: "auto", height: "fit-content", padding: "1vh", paddingTop: "2vh"}} class="userInputDiv">
              <input
                onChange={(c) => handleInputChange(c.target.value)}
                value={userInputText}
                type="text"
                placeholder=""
                style={{cursor: loading ? 'progress' : '', height: "3vw", marginBottom: "1vh"}}
              />
              {recordState !== RecordState.STOP
                ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" onClick={stop}>Stop Recording</button>
                : micEnabled ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Speak</button>
                : <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Enable Mic</button>
              }
              <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={() => checkAnswer(userInputText)} disabled={userInputText===""} >Submit</button>
              <AudioReactRecorder canvasWidth="0"
                canvasHeight="0"
                autoPlay
                state={recordState} onStop={onStop}
                style={{height: 0}} />
            </div>
            <br/> 
        {wrongInput && <a style={{color: "#ba4141"}}>Not quite, try again!</a>}
        </div>

      </div>
    </div>
  );
}

export default TeachingPractice;