import React, {useState, useContext} from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import "./Modal.css";
import { ProgressContext } from "../../../App";
import { Link } from 'react-router-dom';

function SetPlayerName({ setOpenModal, translationSettings, suggestionsSettings}) {
  const progressContext = useContext(ProgressContext);
  const [recordState, setrecordstate] = useState(RecordState.STOP);
  const [userInputText, setUserInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);

  const onStop = (audioData) => {
    var data = new FormData()
    data.append('audio_data', audioData.blob, 'audioInput')
    setLoading(true)
    setrecordstate(RecordState.STOP);
    setMicEnabled(true)
    fetch('https://kf-flask-be-zelhqvxj6q-uc.a.run.app/getVoiceInputTranscribe', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
      .then((data) => {
        setLoading(false)
        console.log(data)
        if (data && typeof data === 'string') {
          if(data.match(/[\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g)) {
            fetch('https://kyokos-friends-node-backend-zelhqvxj6q-uc.a.run.app/furigana', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                aiDialog: data
              })
            }).then((response) => response.json())
              .then((data) => {
                console.log(data)
                if(data.message && data.message.match(/[\u3040-\u309f\u30a0-\u30ff]+/g)){
                  console.log(data.message.match(/[\u3040-\u309f\u30a0-\u30ff]+/g))
                  setUserInputText(data.message.match(/[\u3040-\u309f\u30a0-\u30ff]+/g).join())
                }
              });
            }
            else {
              console.log(data.match(/[\u3040-\u309f\u30a0-\u30ff]+/g))
              if(data.match(/[\u3040-\u309f\u30a0-\u30ff]+/g)){
                setUserInputText(data.match(/[\u3040-\u309f\u30a0-\u30ff]+/g).join())
              }
            }
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  };

  const start = () => {
    console.log("Hitting Start")
    console.log(recordState)
    setrecordstate(RecordState.START);
    setIsRecording(true)
  };

  const stop = () => {
    console.log("Hitting Stop")
    console.log(recordState)
    setrecordstate(RecordState.STOP);
    setIsRecording(false)
  };
  const handleInputChange = (evt) => {
    setUserInputText(evt);
  };

  function setName(name) {
    progressContext.setProgress({
      ...progressContext.progress,
      user_name: name
    })
  }

  return (
    <div style={{cursor: loading ? 'progress' : ''}} className="modalBackground">
      <div style={{height: "70vh"}} className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div style={{marginTop: "-5vh", top: "16vh"}} className="title" >
          <h1 >What's Your Name?</h1>
        </div>
        <div  style={{border: "solid gray", marginTop: "7vh", overflowY: "scroll"}}>
        <h3 style={{marginTop: "2vh", textAlign: "center"}}>Speaking</h3>
        <div  style={{textAlign: "left", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <a >1. Click on "Enable Mic"</a>
          <br/> 
          <a >2.Click on "Speak" to begin recording, press "Stop" when you are done talking.</a>
          <br/> 
          <a >3. If your speech was picked up, it should appear as text after a few moments.</a>
          <br/> 
           <br/> 
          <a> Choose a name with clear syllables that can be recognized by the system easily. ノア (Noah) and さくら (Sakura) are good examples.</a>
        </div>
        <div style={{position: "unset", margin: "auto", height: "fit-content"}} class="userInputDiv">
              <h3> Enter Name: {progressContext.progress.user_name}</h3>
              <input
                onChange={(c) => handleInputChange(c.target.value)}
                value={userInputText}
                type="text"
                placeholder=""
                style={{cursor: loading ? 'progress' : '', height: "3vw"}}
              />
              {recordState !== RecordState.STOP
                ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" onClick={stop}>Stop Recording</button>
                : micEnabled ? <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Speak</button>
                : <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={start}>Enable Mic</button>
              }
              <button style={{cursor: loading ? 'progress' : ''}} class="characterOutputButtons" disabled={false} onClick={() => setName(userInputText)} >Set Name</button>
              
              <AudioReactRecorder canvasWidth="0"
                canvasHeight="0"
                autoPlay
                state={recordState} onStop={onStop}
                style={{height: 0}} />
            </div>
            <br/> 
            <div style={{textAlign: 'center', height: 'auto'}}><Link to="/welcome" style={{textDecoration: "none"}}><button>Continue</button></Link></div>
        </div>

      </div>
    </div>
  );
}

export default SetPlayerName;