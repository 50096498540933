import React, {useContext} from "react";
import "./Modal.css";
import { ProgressContext } from '../../../App'

function NoteModal({ setOpenModal, translationSettings, suggestionsSettings }) {
  const progressContext = useContext(ProgressContext);
  const vocab = [  {    "word": "こんにちは",    "translation": "Hello"  },  {    "word": "はい",    "translation": "Yes"  },  {    "word": "いいえ",    "translation": "No"  },  {    "word": "さようなら",    "translation": "Goodbye"  },  {    "word": "おはようございます",    "translation": "Good morning"  },  {    "word": "こんばんは",    "translation": "Good evening"  },  {    "word": "ありがとう",    "translation": "Thank you"  },  {    "word": "すみません",    "translation": "Excuse me/sorry"  },  {    "word": "はじめまして",    "translation": "Nice to meet you"  },  {    "word": "わたし",    "translation": "I/me"  },  {    "word": "あなた",    "translation": "You"  },  {    "word": "せんせい",    "translation": "Teacher"  },  {    "word": "がくせい",    "translation": "Student"  },  {    "word": "いぬ",    "translation": "Dog"  },  {    "word": "ねこ",    "translation": "Cat"  },  {    "word": "ほん",    "translation": "Book"  },  {    "word": "ざっし",    "translation": "Magazine"  },  {    "word": "しんぶん",    "translation": "Newspaper"  },  {    "word": "てちょう",    "translation": "Notebook"  },  {    "word": "めいし",    "translation": "Business card"  }]
  const greetingsFarewells = [
    {
      "word": "こんにちは",
      "romanji": "konnichiwa",
      "translation": "Hello"
    },
    {
      "word": "おはようございます",
      "romanji": "ohayō gozaimasu",
      "translation": "Good morning"
    },
    {
      "word": "こんばんは",
      "romanji": "konbanwa",
      "translation": "Good evening"
    },
    {
      "word": "じゃあね",
      "romanji": "janae",
      "translation": "Bye"
    },
    {
      "word": "またね",
      "romanji": "matanae",
      "translation": "See ya"
    },
    {
      "word": "さようなら",
      "romanji": "sayōnara",
      "translation": "Goodbye"
    },
    {
      "word": "おやすみなさい",
      "romanji": "oyasumi nasai",
      "translation": "Goodnight"
    }
  ]
  const introductions = [
    {
      "word": "初じめまして",
      "romanji": "hajimemashite",
      "translation": "Nice to meet you"
    },
    {
      "word": "よろしく",
      "romanji": "yoroshku",
      "translation": "Nice to meet you"
    },
    {
      "word": "名前",
      "romanji": "namae",
      "translation": "name"
    }
  ]
  const questions = [
    {
      "word": "何",
      "romanji": "nani",
      "translation": "what"
    },
    {
      "word": "どう",
      "romanji": "dou",
      "translation": "how"
    },
    {
      "word": "誰",
      "romanji": "dare",
      "translation": "Who"
    }
  ]
  const condition = [
    {
      "word": "元気",
      "romanji": "genki",
      "translation": "well, healthy"
    },
    {
      "word": "大丈夫",
      "romanji": "daijoubu",
      "translation": "ok"
    },
    {
      "word": "悪い",
      "romanji": "waruii",
      "translation": "bad"
    },
    {
      "word": "調子",
      "romanji": "choushi",
      "translation": "condition"
    },
    {
      "word": "いい感じ",
      "romanji": "iikanji",
      "translation": "pleasant feeling"
    }
  ]
  const weather = [
    {
      "word": "今日",
      "romanji": "Kyo",
      "translation": "today"
    },
    {
      "word": "天気",
      "romanji": "tenki",
      "translation": "Weather"
    },
    {
      "word": "気温",
      "romanji": "kion",
      "translation": "Temperature"
    },
    {
      "word": "晴れ",
      "romanji": "hare",
      "translation": "Sunny"
    },
    {
      "word": "雨",
      "romanji": "ame",
      "translation": "Rain"
    },
    {
      "word": "曇り",
      "romanji": "kumori",
      "translation": "Cloudy"
    },
    {
      "word": "雪",
      "romanji": "yuki",
      "translation": "snow"
    },
    {
      "word": "寒い",
      "romanji": "samui",
      "translation": "Cold"
    },
    {
      "word": "暑い",
      "romanji": "atsui",
      "translation": "Hot"
    }
  ]
  const yesNo = [
    {
      "word": "うん",
      "romanji": "un",
      "translation": "yes"
    },
    {
      "word": "はい",
      "romanji": "hai",
      "translation": "yes"
    },
    {
      "word": "ええ",
      "romanji": "ee",
      "translation": "yes"
    },
    {
      "word": "そう",
      "romanji": "sou",
      "translation": "right"
    },
    {
      "word": "いいえ",
      "romanji": "iie",
      "translation": "no"
    },
    {
      "word": "いや",
      "romanji": "iya",
      "translation": "no"
    }
  ]
  const comments = [
    {
      "word": "楽しい",
      "romanji": "tanoshi",
      "translation": "fun"
    },
    {
      "word": "最低",
      "romanji": "saite",
      "translation": "the worst"
    },
    {
      "word": "いい",
      "romanji": "ii",
      "translation": "good"
    }
  ]
  const kanji = [
    {
      "character": "今",
      "readings": ["いま"],
      "meanings": ["now", "present"]
    },
    {
      "character": "初",
      "readings": ["はじ", "はじめ", "はつ"],
      "meanings": ["beginning", "first time"]
    },
    {
      "character": "元",
      "readings": ["もと", "げん"],
      "meanings": ["origin", "source", "beginning"]
    },
    {
      "character": "天",
      "readings": ["てん", "あま"],
      "meanings": ["heaven", "sky", "god"]
    },
    {
      "character": "晴",
      "readings": ["はれ"],
      "meanings": ["clear weather", "fine"]
    },
    {
      "character": "雨",
      "readings": ["あめ", "あま"],
      "meanings": ["rain"]
    },
    {
      "character": "曇",
      "readings": ["くも", "くもり"],
      "meanings": ["cloud", "cloudy weather"]
    },
    {
      "character": "寒",
      "readings": ["さむ", "さむい"],
      "meanings": ["cold"]
    },
    {
      "character": "暑",
      "readings": ["あつ", "あつい"],
      "meanings": ["hot", "warm"]
    },
    {
      "character": "雪",
      "readings": ["ゆき"],
      "meanings": ["snow"]
    },
    {
      "character": "名",
      "readings": ["な", "めい"],
      "meanings": ["name", "fame"]
    },
    {
      "character": "前",
      "readings": ["まえ"],
      "meanings": ["front", "before"]
    },
    {
      "character": "誰",
      "readings": ["だれ"],
      "meanings": ["who"]
    },
    {
      "character": "気",
      "readings": ["き"],
      "meanings": ["spirit", "mood", "energy"]
    },
    {
      "character": "大",
      "readings": ["だい", "たい"],
      "meanings": ["big", "large"]
    },
    {
      "character": "丈",
      "readings": ["じょう"],
      "meanings": ["length", "height"]
    }
  ]
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Vocab</h1>
        </div>
          <ul style={{marginTop: "8vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Greetings/Farewells</h1>
          <ul className="vocabList">
            {greetingsFarewells.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>

          <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Introductions</h1>
          <ul className="vocabList">
            {introductions.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>

          <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Asking Questions</h1>
          <ul className="vocabList">
            {questions.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>

          <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Yes/No</h1>
          <ul className="vocabList">
            {yesNo.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>

          {progressContext.progress.openConversation &&  <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Condition</h1>
          <ul className="vocabList">
            {condition.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>}

          {progressContext.progress.openConversation &&  <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Weather</h1>
          <ul className="vocabList">
            {weather.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>}

          {progressContext.progress.openConversation && <ul style={{marginTop: "1vh", columns: 1}} className="vocabList">
          <h1 style={{marginBottom: "1vh"}}>Comments</h1>
          <ul className="vocabList">
            {comments.map((obj, index) => (
              <li className="NoteItem" key={obj.word}>
                <b>{obj.word}</b>  <a> ({obj.romanji})   =    {obj.translation}</a>
              </li>
            ))}
          </ul>
          </ul>  }        
      </div>
    </div>
  );
}

export default NoteModal;