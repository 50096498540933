import React, { useEffect, useState } from "react";
import "./RadioButtons.css";

function CheckBoxes({checkboxLabelList, isCheckedList, setAsCheckedList}) {
  return (
    <div className="buttondDiv">
      {checkboxLabelList && checkboxLabelList.map((cbLabel, index) => (
        <label class="radio-button">
        <input defaultChecked={isCheckedList[index]} type="checkbox" onChange={()=>{setAsCheckedList[index](!isCheckedList[index])}}/>
        <span class="label-visible">
          <span class="fake-checkbox"></span>
          {cbLabel}
        </span>
      </label>
      ))}
    </div>
  );
}

export default CheckBoxes;