import React from "react";
import "./Modal.css";

function DemoInfo({ setOpenModal, translationSettings, suggestionsSettings }) {

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title" >
          <h1>Navigation Map Guide</h1>
        </div>
        <div  style={{textAlign: "center", border: "solid gray", marginTop: "7vh"}}>
        <div  style={{textAlign: "center", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <a></a>
        </div>
        <div  style={{textAlign: "left", marginTop: "-0.5vh", height: "auto", width: "auto", padding: "1vw"}}>
          <a >You can use the Navigation Map to check your current Tasks and navigate to content to complete them. </a>
          <br/>
          <br/>
          <b style={{color: "rgb(240 240 240)"}}>Tasks:</b> <a>A list of work items provided by your new employer. Check here if you're unsure what to do next.</a>
          <br/> 
          <br/> 
          <b style={{color: "rgb(240 240 240)"}}>Lessons:</b> <a>Daichi will walk you through some specific grammar and vocab in Lessons so you can converse with the residents.</a>
          <br/> 
          <br/> 
          <b style={{color: "rgb(240 240 240)"}}>Guided Conversations:</b> <a>Practice a specific conversation with Kyoko; suggestions are available to help you get through the conversation and there are limited things you can say in conversation.</a>
          <br/> 
          <br/> 
          <b style={{color: "rgb(240 240 240)"}}>Open Conversations:</b> <a>Talk about literally anything with Ren or Yuto. They will speak using N5 Japanese.</a>
          <br/> 
          <br/> 
          <b style={{color: "rgb(240 240 240)"}}>Vocab and Grammar Notes:</b> <a>Notes covering the main vocab and grammar covered in Lessons and used in the Guided Conversations. </a>
          <br/> 
          <br/> 
          <b style={{color: "rgb(240 240 240)"}}>Artifacts:</b> <a>Material provided by the Boukyaku Neighborhood Association to help you better understand the residents. This is only provided to you to perform your job and is not to be shared with anyone living in the neighborhood.</a>
        </div>
        </div>

      </div>
    </div>
  );
}

export default DemoInfo;