import React, {useContext} from "react";
import "./Modal.css";
import RadioButtons from "../InputListComponents/RadioButtons.js";
import CheckBoxes from "../InputListComponents/CheckBoxes.js";
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../../../App'

function LeaveModal({ setOpenModal }) {
  const progressContext = useContext(ProgressContext);
  let navigate = useNavigate(); 
  const routeChange = () =>{
    if (progressContext.progress.openConversation && !progressContext.progress.conclusionCutscene) {
      navigate('/conclusionScene');
    }
    else {
      navigate('/chapterNav');
    }
  }

  return (
    <div className="modalBackground">
      <div className="leaveModalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => {setOpenModal(false)}}>
            X
          </button>
        </div>
          <h1 style={{alignSelf: 'center', fontSize: '2.5vw'}}>Leave?</h1>
          <div style={{display: 'flex', width: '81%', alignSelf: 'center'}}>
            <button onClick={() => {setOpenModal(false)}} style={{fontSize: '1.6vw', height: '3.8vw'}}>Cancel</button>
            {/* <Link to="/chapterNav" ><button style={{fontSize: '1.6vw'}}>Leave</button></Link> */}
            <button style={{fontSize: '1.6vw', height: '3.8vw'}} onClick={routeChange}>Confirm</button>
          </div>
      </div>
    </div>
  );
}

export default LeaveModal;