// bg
const microphone = require("../bg/microphone.jpeg");
const livingRoomBackground = require("../../../styles/backgroundImages/neighborhoodEntrance.jpg")
const entrance = require("../bg/entrance.jpeg");
// bgm
const take = require("../bgm/snowyDay.mp3");
// speakers
const b = "Block";
const daichi = "Daichi"
// sprites
const bn = require('../../../styles/Kyoko/neutralListening.png')
const bh = require('../../../styles/Kyoko/neutralListening.png')
const bp = require('../../../styles/Kyoko/neutralListening.png')

const neutral_listen = require('../../../styles/Kyoko/neutralListening.png')
const neutral_listenD = require('../../../styles/Daichi/neutralListening.png')
const neutral_speakingD = require('../../../styles/Daichi/neutralSpeaking.png')
const happy_listenD = require('../../../styles/Daichi/happyListening.png')
const happy_speakingD = require('../../../styles/Daichi/happySpeaking.png')
//const neutral_talk from '../../styles/Kyoko/neutralSpeaking.png'

let welcomeBoukyakuStory = [
  {
    bg: livingRoomBackground,
    bgm: take,
    sprite: neutral_listenD,
    speaker: daichi,
    text: " "
  },
  {
    sprite: neutral_speakingD,
    text: "Ah- hello. Glad to see you made it here without any complications."
  },
  {
    sprite: happy_speakingD,
    text: "On behalf of the Boukyaku Neighborhood Association–welcome!  We’re thrilled to have you on the project."
  },
  {
    sprite: neutral_speakingD,
    text: "Boukyaku and all of the residents are fairly new so you could really make an impact on the trajectory of the project as one of our first consultants."
  },
  {
    text:
      "The work you’ll be doing is fairly simple–we just want you to check in with the residents to see how they’re adjusting to life here and help address any issues that might come up. We care deeply about the stability and happiness of our residents here so your work is extremely important."
  },
  {
    text:
      "I’ve been told you’re still learning Japanese. Not to worry, myself and the other residents are happy to help you get your bearings. I can teach you some basics and then you can practice with your new neighbors."
  },
  {
    text:
      "I’ll let you explore on your own a bit, I can give you your first lesson whenever you’re ready. If you’re ever unsure of what to do next, check your ‘Tasks’."
  },
  {
    sprite: happy_speakingD,
    text:
      "Talk to you soon!"
  },
  {
    sprite: happy_listenD,
    text:
      " "
  }
];

// The code below is to set undefined properties to the last defined property.
// It is optional and based on preference, so feel free to add or remove any function calls.

setFutureProperties("bg");
setFutureProperties("bgm");
setFutureProperties("speaker");
setFutureProperties("sprite");
setFutureProperties("spriteLeft");
setFutureProperties("spriteRight");

function setFutureProperties(key) {
  let cache = "";
  for (let obj of welcomeBoukyakuStory) {
    if (obj[key] || obj[key] === "") {
      cache = obj[key];
    } else {
      obj[key] = cache;
    }
  }
}
export default welcomeBoukyakuStory;
