import React, { useState } from 'react';
import './Friendships.css';
import NoteModal from "../../ConversationComponents/Modal/NoteModal";
import VocabModal from "../../ConversationComponents/Modal/VocabModal";
import TasksModal from "../../ConversationComponents/Modal/TasksModal";
import ConversationModal from "../../ConversationComponents/Modal/ConversationModal";

import diaryIcon from "./../diaryIcon.png"
import diaryIconLocked from "./../diaryIconLocked.png"
import phoneIcon from "./../phoneIcon.png"
import phoneIconAlt from "./../phoneIconAlt.png"
import phoneIconLocked from "./../phoneIconLocked.png"
import stickyNoteIcon from "./../stickyNoteIcon.png"

import KyokoFace from "../../ConversationComponents/Modal/charFaceShots/KyokoFaceShot.png";
import YutoFace from "../../ConversationComponents/Modal/charFaceShots/YutoFaceShot.png";
import DaichiFace from "../../ConversationComponents/Modal/charFaceShots/DaichiFaceShot.png";
import RenFace from "../../ConversationComponents/Modal/charFaceShots/RenFaceShot.png";
import LockFace from "../../ConversationComponents/Modal/charFaceShots/LockIconGrey.png";


import KyokoFullBody from "../../../styles/Kyoko/neutralListening.png"

function Friendships() {
    const [TasksModalOpen, setTasksModalOpen] = useState(false);
    const [NotesModalOpen, setNotesModalOpen] = useState(false);
    const [VocabModalOpen, setVocabModalOpen] = useState(false);
    const [ConversationModalOpen, setConversationModalOpen] = useState(false);
    const characterListItems = ['Chapter 1', 'Chapter 2', 'Chapter 3', 'Chapter 4', 'Chapter 5'];
    

    return (
        <div >
          <div className="container">
            <div className="characterList">
              <ul>
              <li key={"kyoko"}><div className="charSelectionDiv">
                <img src={KyokoFace}/>
                <a>京子 (Kyoko)</a>
                <a className="friendshipLevel">FR 4 </a>
                <div className="barEmpty"></div>
                <div className="barFilling"></div>
              </div></li>
              <li key={"yuto"}><div className="charSelectionDiv">
                <img src={YutoFace}/>
                <a>優翔 (Yuto)</a>
                <a className="friendshipLevel">FR 1 </a>
                <div className="barEmpty"></div>
                <div style={{width: "8vw"}} className="barFilling"></div>
              </div></li>
              <li key={"yuto"}><div className="charSelectionDiv">
                <img src={DaichiFace}/>
                <a>大地 (Daichi)</a>
                <a className="friendshipLevel">FR 2 </a>
                <div className="barEmpty"></div>
                <div style={{width: "6vw"}} className="barFilling"></div>
              </div></li>
              <li key={"yuto"}><div className="charSelectionDiv">
                <img src={RenFace}/>
                <a>蓮 (Ren)</a>
                <a className="friendshipLevel">FR 0 </a>
                <div className="barEmpty"></div>
                <div style={{width: "2vw"}} className="barFilling"></div>
              </div></li>
              <li key={"locked1"}><div className="charSelectionDiv">
                <img src={LockFace}/>
                <a style={{color: "#b2acac"}}>LOCKED</a>
                <a className="friendshipLevel" style={{color: "#b2acac"}}>FR 0 </a>
                <div className="barEmpty"></div>
              </div></li>
              <li key={"locked2"}><div className="charSelectionDiv">
                <img src={LockFace}/>
                <a style={{color: "#b2acac"}}>LOCKED</a>
                <a className="friendshipLevel" style={{color: "#b2acac"}}>FR 0 </a>
                <div className="barEmpty"></div>
              </div></li>
              </ul>
            </div>

            
            <div className="content">
              
              <div className="characterProfile">
                <h1>真崎 京子 - Kyoko Masaki</h1>
                <div className="container">
                <div style={{background: "rgb(221 217 217)"}} className="content"> 
                <img src={KyokoFullBody}/>
                </div>
                <div style={{background: "rgb(221 217 217)"}} className="content"> 
                <div style={{marginTop: "8.6vw"}}>
                    <b>Description:</b>
                  </div>
                  <div style={{marginTop: "1vw"}}>
                    <a >Kyoko is 22 year old university student who likes reading and walking around the city. She is cheerful and straightforward, and is always happy to make new friends. Kyoko tries to face difficult truths head on, sometimes at her own expense. </a>
                  </div>
                  <div style={{marginTop: "5.5vw"}}>
                    <b>Current Mood: </b> <a> Neutral</a>
                  </div>
                  <div style={{marginTop: "1.5vw"}}>
                    <b>Current Location: </b> <a> Bedroom</a>
                  </div>
                </div>
                </div>
                {/* <img src={KyokoFullBody}/> */}
                {/* <div className="button-group">
                    <button onClick={() => {setConversationModalOpen(true); }}>Introductions</button>
                    <button>Talking about the weather</button>
                <div style={{height: "auto"}}>
                  <div  className="button-group">
                  <button>Open Conversation</button>
                  </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {TasksModalOpen && <TasksModal setOpenModal={setTasksModalOpen} />}
          {NotesModalOpen && <NoteModal setOpenModal={setNotesModalOpen} />}
          {VocabModalOpen && <VocabModal setOpenModal={setVocabModalOpen} />}
          {ConversationModalOpen && <ConversationModal setOpenModal={setConversationModalOpen} />}
        </div>
      );
    }

export default Friendships;