import React from "react";
import "./Modal.css";
import kyokoFace from "./charFaceShots/KyokoFaceShot.png"
import notebookIcon from "../../HomeComponents/diaryIcon.png"
import yutoFace from "./charFaceShots/YutoFaceShot.png"
import DaichiFace from "./charFaceShots/DaichiFaceShot.png"
import RenFace from "./charFaceShots/RenFaceShot.png"
import { Link } from 'react-router-dom';


function ArtifactSelectionModal({ setOpenModal, setBookArtifactOpen, setAudioArtifactOpen }) {

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Artifacts</h1>
        </div>
          <ul className="artifactList">
            <li className="charFaceShot">
              <div className="artifactListHover" onClick={()=>setBookArtifactOpen(true)}>
              <img  src={notebookIcon}/>
              <div ><b className="artifactTitle">Document_006.png</b></div>
              </div>
            </li>
            <li className="charFaceShot">
              <div className="artifactListHover" onClick={()=>setAudioArtifactOpen(true)}>
              <img  src={notebookIcon}/>
              <div ><b className="artifactTitle">Document_021.png</b></div>
              </div>
            </li>
            <li className="charFaceShot">
            <Link to="/guidedConvDemoIntro" style={{textDecoration: "none"}}>
              <div className="artifactListHover" onClick={()=>setAudioArtifactOpen(true)}>
              <img  src={notebookIcon}/>
              <div ><b className="artifactTitle">Document_006.png</b></div>
              </div>
            </Link>
            </li>

            <li className="charFaceShot">
            <Link to="/guidedConvDemoIntro" style={{textDecoration: "none"}}>
              <div onClick={console.log("click")}>
              <img  src={notebookIcon}/>
              <div ><b>Document_031.mp3</b></div>
                
                </div>
            </Link>
            </li>
          </ul>
      </div>
    </div>
  );
}

export default ArtifactSelectionModal;