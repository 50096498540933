import React, {useContext} from "react";
import "./Modal.css";
import checkedBoxIcon from "./checkedBoxIcon.png"
import boxIcon from "./boxIcon.png"
import checkedBoxIconDark from "./checkedBoxIconDark.png"
import boxIconDark from "./boxIconDark.png"
import { ProgressContext } from '../../../App'

function NoteModal({ setOpenModal, translationSettings, suggestionsSettings }) {
  const progressContext = useContext(ProgressContext);

  const tasks = [  
  {    
    title: "Learn How to Introduce Yourself from Daichi",
    contents: [      
      "Learn the basics of introducing yourself from Daichi. Complete the 'Introductions' which can be found under 'Lessons'"
    ],
    completed: progressContext.progress.introductionLesson,
    reward: "'Introductions' Guided Conversation"
  },
  {    
    title: "Introduce Yourself to Kyoko",
    contents: [      
      "Meet Kyoko and practice what you just learned from Daichi by having the 'Introductions' Guided Conversation with her."
    ],
    completed: progressContext.progress.introductionGuidedConv,
    reward: "Open Conversations"
  },
  {    
    title: "Try an Open Conversation",
    contents: [      
      "Ren and Yuto are both a bit odd, but why not get to know them too? Have an Open Conversation with one of them; you can introduce yourself or talk about whatever you want."
    ],
    completed: progressContext.progress.openConversation,
    reward: "Artifact #1"
  }
]
  const optionalTasks = [  
]
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Tasks</h1>
        </div>
        <h2 className="FirstTaskSectionTitle"></h2>
          <ul className="noteList">
            {tasks.map((obj, index) => (
              <li className="TaskItem" key={obj.title}>
                <h3 style={obj.completed ? {textDecoration:"line-through"} : {}} className="taskListItem">{index+1}. {obj.title}</h3>
                {obj.completed ? <img className="taskCheckBox" src={checkedBoxIcon}/>
                  : <img className="taskCheckBox" src={boxIcon}/>}
                <ul className="taskListItem">
                  {obj.contents.map((contentBullet, index) => (
                    <li key={contentBullet}>
                      <a style={obj.completed ? {textDecoration:"line-through"} : {}}>{contentBullet}</a>
                    </li>
                  ))}
                  {obj.status && <a className="taskListItem" style={obj.completed ? {textDecoration:"line-through"} : {}}>Status: <a style={{fontWeight: "normal"}}>{obj.status}</a> </a>}
                  {obj.reward.length > 0 && <a className="taskListItem rewardText" style={obj.completed ? {textDecoration:"line-through"} : {}}>Unlocks: <a style={{fontWeight: "normal"}}>{obj.reward}</a> </a>}
                </ul>
              </li>
            ))}
          </ul>
          
      </div>
    </div>
  );
}

export default NoteModal;