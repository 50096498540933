import React, {useState} from "react";
import { Markup } from 'interweave';
import "./styles.css";

const HoverTranslation = ({ prompt, promptTranslation, promptAudio, response, responseTranslation,
    responseAudio, isAudioDisabled, disableAudioFunction,
    promptFurigana, responseFurigana, isFuriganaEnabled, isSemanticTranslationEnabled,
    promptWBW, responseWBW, isWBWTransEnabled}) => {

  const [style, setStyle] = useState({display: 'none'});
  const [responseTranslationTextStyle, setResponseTranslationTextStyle] = useState({color: 'rgba(201, 201, 201, 0)'});
  const [promptTranslationTextStyle, setPromptTranslationTextStyle] = useState({color: 'rgba(201, 201, 201, 0)'});
  const [promptHover, setPromptHover] = useState(0);
  const [responseHover, setResponseHover] = useState(0);

  const wBw = [["a","a Trans (note here) "],["b","b Trans )note "]]

  const setSemanticTranslationTextStyle = (styleSetting, promptOrResponse) =>
  {
      if (isSemanticTranslationEnabled) {
        if (promptOrResponse === "PROMPT") {
            setPromptTranslationTextStyle(styleSetting)
        }
        else if (promptOrResponse === "RESPONSE"){
            setResponseTranslationTextStyle(styleSetting)
        }
    }
  }

  const playPhraseAudio = (audioBlob) =>
  {
    console.log("User clicked on phrase to play translation")
    if(audioBlob !== null && isAudioDisabled === false) {
        try {
            disableAudioFunction(true)
            var audioURL = URL.createObjectURL(audioBlob)
            var audio = new Audio(audioURL)
            audio.play()
            var duration = 1
            audio.addEventListener('loadedmetadata', function () {
                duration = audio.duration;
                console.log("Playing audio of duration: " + duration)

                var totalTime = (1000 * duration) + 400
                const timer = setTimeout(() => {
                //set character portrait to listening
                //setPortaitListening(emotion)
                disableAudioFunction(false)
                }, totalTime);
                return () => clearTimeout(timer);

            }, false);
          }
          catch (e){
            disableAudioFunction(false)
            console.log("could not play audio")
            console.error(e)
          }
    }
  }

  return (
            <div class="charSpeechDiv">
                {response !== "blank" ?
                    <div className="charSpeechPhraseDiv" onClick={() => playPhraseAudio(responseAudio)} 
                    onMouseEnter={e => {
                        setSemanticTranslationTextStyle({color: 'rgba(201, 201, 201, 1)'}, "RESPONSE");
                        setResponseHover(1);
                    }}
                    onMouseLeave={e => {
                        setSemanticTranslationTextStyle({color: 'rgba(201, 201, 201, 0)'}, "RESPONSE")
                        setResponseHover(0);
                    }} >
                        {responseHover === 1 && isFuriganaEnabled && responseFurigana ? <div> <Markup content={responseFurigana} />  </div>
                        : <div className="noFuriganadiv"> <a>{response}</a> </div>}
                    
                    </div>  : 
                    <a></a>
                }
                
                {prompt !== "blank" ?
                    <div className="charSpeechPhraseDiv" onClick={() => playPhraseAudio(promptAudio)} 
                    onMouseEnter={e => {
                        setSemanticTranslationTextStyle({color: 'rgba(201, 201, 201, 1)'}, "PROMPT")
                        setPromptHover(1);
                    }}
                    onMouseLeave={e => {
                        setSemanticTranslationTextStyle({color: 'rgba(201, 201, 201, 0)'}, "PROMPT")
                        setPromptHover(0);
                    }}>
                        {promptHover === 1 && isFuriganaEnabled && promptFurigana ? <div>  <Markup content={promptFurigana} /> </div> 
                        : <div className="noFuriganadiv"> <a>{prompt}</a> </div>}
                    
                    </div> :
                    <a></a>
                }

            <div>
            <a style={responseTranslationTextStyle}> {responseTranslation["semantic"]} </a> 
            <a style={promptTranslationTextStyle}> {promptTranslation["semantic"]} </a> 
            </div>
            {responseHover === 1 &&  isWBWTransEnabled  ?<div className='wordByWordTranslationDiv'>
                        {responseWBW.map((item, index) => (
                            <div className="itemRow1" style={{ borderLeft: index === 0? 'none': 'solid #797979'}}>
                                <a key={index}>{item[0]}</a>
                            </div>
                        ))}
                        {responseWBW.map((item, index) => (
                            <div className="itemRow2" style={{ borderLeft: index === 0? 'none': 'solid #797979'}}>
                                <a key={index}>{item[1]}</a>
                            </div>
                        ))}
                    </div>:<a></a> }
            {promptHover === 1 &&  isWBWTransEnabled  ?<div className='wordByWordTranslationDiv'>
                    {promptWBW.map((item, index) => (
                        <div className="itemRow1" style={{ borderLeft: index === 0? 'none': 'solid #797979'}}>
                            <a key={index}>{item[0]}</a>
                        </div>
                    ))}
                    {promptWBW.map((item, index) => (
                        <div className="itemRow2" style={{ borderLeft: index === 0? 'none': 'solid #797979'}}>
                            <a key={index}>{item[1]}</a>
                        </div>
                    ))}
                    </div>:<a></a> }

            </div>
  );
};

export default HoverTranslation;
