import React, {useContext} from "react";
import "./Modal.css";
import { ProgressContext } from '../../../App'

function NoteModal({ setOpenModal, translationSettings, suggestionsSettings }) {
  const progressContext = useContext(ProgressContext);
//   const notes = [  
//   {
//     title: "The Verb 'です' (Desu)",
//     contents: [
//       "'です' (desu) is the present tense form of a verb meaning 'to be'. It is often used at the end of a sentence declaring what something 'is'",
//       "X + です = 'it is x'",
//       "楽しいです (tanoshi desu) means 'It is fun.' ",
//       "EXAMPLE 2: りんごです (ringo desu) means 'It is an apple.' ",
//       "です is often used as part of a more complex sentences that explicitly state the subject of the sentence (as shown below), but it can also be used in very simple sentences like in the examples where the subject of the sentence depends on the context of the conversation. If I am talking to someone about baseball I could say 楽しいです and they would know I am talking about baseball being fun because that was what we were talking about before."
//     ]
//   },
//   {
//     title: "The Particle 'は' (Wa)",
//     contents: [
//       "The particle 'は' (wa) is used to mark the topic of a sentence.",
//       "For example: 私は学生です (Watashi wa gakusei desu) means 'As for me, I am a student.'",
//       "In this example, the topic is 私 (watashi), which is marked by the particle 'は' (wa)."
//     ]
//   },
//   {
//     title: "The Particle 'か' (Ka)",
//     contents: [
//       "The particle 'か' (ka) is used to indicate a question.",
//       "For example: あなたは学生ですか (Anata wa gakusei desu ka) means 'Are you a student?'",
//       "In this example, the particle 'か' (ka) is used to indicate that the sentence is a question."
//     ]
//   },
//   {
//     title: "What is ... ? with 何 (nani)",
//     contents: [
//       "The particle 'の' (no) is used to indicate possession.",
//       "For example: 私の猫 (Watashi no neko) means 'my cat'.",
//       "In this example, '私' (watashi) means 'I' and '猫' (neko) means 'cat'. The particle 'の' (no) is used to indicate that the cat belongs to me."
//     ]
//   },
//   {
//     title: "The Particle 'の' (No)",
//     contents: [
//       "The particle 'の' (no) is used to indicate possession.",
//       "For example: 私の猫 (Watashi no neko) means 'my cat'.",
//       "In this example, '私' (watashi) means 'I' and '猫' (neko) means 'cat'. The particle 'の' (no) is used to indicate that the cat belongs to me."
//     ]
//   },
//   {    
//     title: "Sentence Structure",
//     contents: [      
//       "In Japanese, the basic sentence structure is Subject - Object - Verb (SOV).",
//       "For example: 私はりんごを食べます (Watashi wa ringo wo tabemasu) means 'I eat an apple.'",
//       "The subject is 私 (watashi), the object is りんご (ringo), and the verb is 食べます (tabemasu)."
//     ]
//   },
//   {
//     title: "The Particle 'を' (Wo)",
//     contents: [
//       "The particle 'を' (wo) is used to indicate the object of a sentence.",
//       "For example: りんごを食べます (Ringo wo tabemasu) means 'I eat an apple.'",
//       "In this example, 'りんご' (ringo) means 'apple', and 'を' (wo) is used to indicate that the apple is the object of the sentence."
//     ]
//   },
//   {
//     title: "The Particle 'が' (Ga)",
//     contents: [
//       "The particle 'が' (ga) is used to indicate the subject of a sentence.",
//       "For example: 犬が好きです (Inu ga suki desu) means 'I like dogs.'",
//       "In this example, '犬' (inu) means 'dog', and 'が' (ga) is used to indicate that it's the subject"
//     ]
//   }
// ]
const notes = [  
  {
    title: "The Copula 'です' (Desu)",
    contents: [
      "'です' (desu) is the present tense form of a verb meaning 'to be'. It is often used at the end of a sentence declaring what something 'is'",
      "Y + です = 'It is Y'",
      "楽しい です (tanoshi desu)",
      "literal translation = 'It is fun'",
      "semantic translation = 'It (the topic of current conversation) is fun'",
      "りんご です (ringo desu)",
      "literal translation = 'It is an apple'",
      "semantic translation = 'It (the topic of current conversation) is an apple'",
      "です is often used as part of a more complex sentences that explicitly state the subject of the sentence (as shown below), but it can also be used in very simple sentences like in the examples where the subject of the sentence depends on the context of the conversation. If I am talking to someone about baseball I could say 楽しいです and they would know I am talking about baseball being fun because that was what we were talking about before."
    ]
  },
  {
    title: "The Particle 'は' (Wa)",
    contents: [
      "The particle 'は' (wa) is used to mark the topic of a sentence. 'は' can be translated to 'Regarding' or 'Speaking of' in english and marks the word previous to itself as the topic of the sentence",
      "X は Y です = Regarding X, it is Y",
      "私 は 京子 です (Watashi wa Kyoko desu) ",
      "literal translation = 'Regarding me, it is Kyoko.'",
      "semantic translation = 'I am Kyoko' ",
      "今日 は 雨 です (Kyo wa ame desu)",
      "literal translation = 'Regarding today, it is rain.'",
      "semantic translation = 'It's rainy today' ",
    ]
  },
  {
    title: "The Particle 'か' (Ka)",
    contents: [
      "The particle 'か' (ka) is used to indicate a question. It can be roughly translated to ? (a question mark) in english and typically goes at the end of a sentence to make it a question.",
      "X は Y です か =  Regarding X, is it Y?",
      "曇り です か (Kumori desu ka)",
      "literal translation = 'Is it cloudy?'",
      "semantic translation = 'Is it cloudy?'",
      "京子 は 元気 です か (Kyoko wa genki desu ka)",
      "literal translation = 'Regarding Kyoko, is it well?'",
      "semantic translation = 'Is Kyoko well?'  OR  (Speaking to Kyoko) 'How are you?' ",
    ]
  },
  {
    title: "What is ... ? with 何 (nani)",
    contents: [
      "何 (nani) means 'what' in japanese and can be used in sentences to ask what something is.",
      "X は 何 です か =  Regarding X, what is it?",
      "名前 は 何 です か (Namae wa nan desu ka)",
      "literal translation = 'Regarding name, what is it?'",
      "semantic translation = 'What is your name?'",
      "天気 は 何 です か (Tenki wa nan desu ka)",
      "literal translation = 'Regarding the weather, what is it?'",
      "semantic translation = 'What is the weather?' ",
    ]
  },
  {
    title: "The Particle 'の' (No) [Possessive]",
    contents: [
      "The 'の' (No) particle can be used to indicate possession. The possessive の is usually placed between the owner and the belonging in a sentence, the literal translation of the possessive の would look something like \"of\" or \" 's \" or \"belonging to\"",
      "X の Y =  X's Y",
      "優翔 の 猫 (Yuto no neko)",
      "literal translation = 'Cat belonging to Yuto'",
      "semantic translation = 'Yuto's cat' ",
      "私 の 名前 は 京子 です (Watashi no namae wa Kyoko desu)",
      "literal translation = 'Regarding the name of me, it is Kyoko'",
      "semantic translation = 'My name is Kyoko'",
      "The の particle can also be used to indicate position (below, in front of, etc) AND as a general noun modifier, these cases will be covered in future chapters."
    ]
  }
]
const notes2 = [  
  {
    title: "The Copula 'です' (Desu)",
    contents: [
      "'です' (desu) is the present tense form of a verb meaning 'to be'. It is often used at the end of a sentence declaring what something 'is'",
      "Y + です = 'It is Y'",
      "楽しい です (tanoshi desu)",
      "literal translation = 'It is fun'",
      "semantic translation = 'It (the topic of current conversation) is fun'",
      "りんご です (ringo desu)",
      "literal translation = 'It is an apple'",
      "semantic translation = 'It (the topic of current conversation) is an apple'",
      "です is often used as part of a more complex sentences that explicitly state the subject of the sentence (as shown below), but it can also be used in very simple sentences like in the examples where the subject of the sentence depends on the context of the conversation. If I am talking to someone about baseball I could say 楽しいです and they would know I am talking about baseball being fun because that was what we were talking about before."
    ]
  },
  {
    title: "The Particle 'は' (Wa)",
    contents: [
      "The particle 'は' (wa) is used to mark the topic of a sentence. 'は' can be translated to 'Regarding' or 'Speaking of' in english and marks the word previous to itself as the topic of the sentence",
      "X は Y です = Regarding X, it is Y",
      "私 は 京子 です (Watashi wa Kyoko desu) ",
      "literal translation = 'Regarding me, it is Kyoko.'",
      "semantic translation = 'I am Kyoko' ",
      "今日 は 雨 です (Kyo wa ame desu)",
      "literal translation = 'Regarding today, it is rain.'",
      "semantic translation = 'It's rainy today' ",
    ]
  },
  {
    title: "The Particle 'か' (Ka)",
    contents: [
      "The particle 'か' (ka) is used to indicate a question. It can be roughly translated to ? (a question mark) in english and typically goes at the end of a sentence to make it a question.",
      "X は Y です か =  Regarding X, is it Y?",
      "曇り です か (Kumori desu ka)",
      "literal translation = 'Is it cloudy?'",
      "semantic translation = 'Is it cloudy?'",
      "京子 は 元気 です か (Kyoko wa genki desu ka)",
      "literal translation = 'Regarding Kyoko, is it well?'",
      "semantic translation = 'Is Kyoko well?'  OR  (Speaking to Kyoko) 'How are you?' ",
    ]
  },
  {
    title: "What is ... ? with 何 (nani)",
    contents: [
      "何 (nani) means 'what' in japanese and can be used in sentences to ask what something is.",
      "X は 何 です か =  Regarding X, what is it?",
      "名前 は 何 です か (Namae wa nan desu ka)",
      "literal translation = 'Regarding name, what is it?'",
      "semantic translation = 'What is your name?'",
      "天気 は 何 です か (Tenki wa nan desu ka)",
      "literal translation = 'Regarding the weather, what is it?'",
      "semantic translation = 'What is the weather?' ",
    ]
  },
  {
    title: "How is ... ? with どう (dou)",
    contents: [
      "どう (dou) means 'how' in japanese and can be used in sentences to ask how something is.",
      "X は どう です か =  Regarding X, how is it?",
      "最近 は どう です か (Saikin wa dou desu ka)",
      "literal translation = 'Regarding recently, how is it?'",
      "semantic translation = 'How are you these days?'",
      "気温 は どう です か (Kion wa dou desu ka)",
      "literal translation = 'Regarding the temperature, how is it?'",
      "semantic translation = 'How is the temperature?' ",
    ]
  },
  {
    title: "The Particle 'の' (No) [Possessive]",
    contents: [
      "The 'の' (No) particle can be used to indicate possession. The possessive の is usually placed between the owner and the belonging in a sentence, the literal translation of the possessive の would look something like \"of\" or \" 's \" or \"belonging to\"",
      "X の Y =  X's Y",
      "優翔 の 猫 (Yuto no neko)",
      "literal translation = 'Cat belonging to Yuto'",
      "semantic translation = 'Yuto's cat' ",
      "私 の 名前 は 京子 です (Watashi no namae wa Kyoko desu)",
      "literal translation = 'Regarding the name of me, it is Kyoko'",
      "semantic translation = 'My name is Kyoko'",
      "The の particle can also be used to indicate position (below, in front of, etc) AND as a general noun modifier, these cases will be covered in future chapters."
    ]
  }
]
let shownNotes = notes
if (progressContext.progress.openConversation) {
  shownNotes = notes2
}
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Grammar Notes</h1>
        </div>
          <ul style={{marginTop: "8.2vh"}} className="noteList">
            {shownNotes.map((obj, index) => (
              // <li className="NoteItem" key={obj.title}>
              //   <h3>{obj.title}</h3>
              //   <ul>
              //   <li key={notes[0].contents[0]}>
              //         <a>{notes[0].contents[0]}</a>
              //     </li>
              //     {obj.contents.map((contentBullet, index) => (
              //       <li key={contentBullet}>
              //         <a>{contentBullet}</a>
              //       </li>
              //     ))}
              //   </ul>
              // </li>
              <li className="NoteItem" key={obj.title}>
                <h3>{obj.title}</h3>
                <ul>
                  <li key={obj.contents[0]}>
                      <a>{obj.contents[0]}</a>
                  </li>
                  <li key={obj.contents[1]}>
                      <b>USAGE: {obj.contents[1]}</b>
                  </li>
                  <li key={obj.contents[2]}>
                      <b>EXAMPLE 1: {obj.contents[2]}</b>
                      <div><a>{obj.contents[3]}</a></div>
                      <div><a>{obj.contents[4]}</a></div>
                  </li>
                  <li key={obj.contents[5]}>
                      <b>EXAMPLE 2: {obj.contents[5]}</b>
                      <div><a>{obj.contents[6]}</a></div>
                      <div><a>{obj.contents[7]}</a></div>
                  </li>
                  {obj.contents[8] && <li key={obj.contents[1]}>
                      <b style={{color:"rgb(131 131 131)"}}>NOTE: </b><a style={{color:"rgb(131 131 131)"}}>{obj.contents[8]}</a>
                  </li> }
                </ul>
            </li>
              

              
            ))}
          </ul>
            
      </div>
    </div>
  );
}

export default NoteModal;