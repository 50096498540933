// bg
const microphone = require("../bg/microphone.jpeg");
const livingRoomBackground = require("../../../styles/backgroundImages/neighborhoodEntrance.jpg")
const entrance = require("../bg/entrance.jpeg");
// bgm
const take = require("../bgm/snowyDay.mp3");
// speakers
const b = "Block";
const daichi = "Daichi"
// sprites
const bn = require('../../../styles/Kyoko/neutralListening.png')
const bh = require('../../../styles/Kyoko/neutralListening.png')
const bp = require('../../../styles/Kyoko/neutralListening.png')

const neutral_listen = require('../../../styles/Kyoko/neutralListening.png')
const neutral_listenD = require('../../../styles/Daichi/neutralListening.png')
const neutral_speakingD = require('../../../styles/Daichi/neutralSpeaking.png')
const happy_listenD = require('../../../styles/Daichi/happyListening.png')
const happy_speakingD = require('../../../styles/Daichi/happySpeaking.png')
//const neutral_talk from '../../styles/Kyoko/neutralSpeaking.png'

let demoConclusionStory = [
  {
    bg: livingRoomBackground,
    bgm: take,
    sprite: happy_listenD,
    speaker: daichi,
    text: "..."
  },
  {
    sprite: happy_speakingD,
    text: "Glad to see you're making the rounds with the other residents."
  },
  {
    text: "I don't have anymore time to help you right now, but the other residents might still be willing to help you practice your Japanese today."
  },
  {
    sprite: neutral_speakingD,
    text: "By the way, I'll be giving you some materials regarding the residents as you get more comfortable in your role. It'll be a mix of documents and other things, but it should help you with your work here."
  },
  {
    text: "You might not be able to understand all of it at this point, but I'm sure it'll make more sense as you continue your work here."
  },
  {
    text: "The materials contain sensitive information so please don't discuss their contents with anyone. The residents all moved here to get a fresh start, so lets make sure they get that."
  },
  {
    sprite: happy_speakingD,
    text:
      "Well, I've got to get going. Thanks for your hard work today, I'll see you again soon, okay?"
  }
];

// The code below is to set undefined properties to the last defined property.
// It is optional and based on preference, so feel free to add or remove any function calls.

setFutureProperties("bg");
setFutureProperties("bgm");
setFutureProperties("speaker");
setFutureProperties("sprite");
setFutureProperties("spriteLeft");
setFutureProperties("spriteRight");

function setFutureProperties(key) {
  let cache = "";
  for (let obj of demoConclusionStory) {
    if (obj[key] || obj[key] === "") {
      cache = obj[key];
    } else {
      obj[key] = cache;
    }
  }
}
export default demoConclusionStory;
